import { FC, useEffect, useRef, useState } from 'react';
import { Box, Stack, SxProps, Theme } from '@mui/material';
import {
  addrComponentsToRecord,
  AddressComponentMapType,
  AddressComponentType,
} from '@/components/Google/Autocomplete/utils/addrComponentsToRecord';
import { GoogleMap } from '@/components/Google/Map/GoogleMap';
import { GoogleAutocomplete } from '@/components/Google/Autocomplete/GoogleAutocomplete';
import { useGeocoder } from '@/components/Google/Map/useGeocoder';

export interface FullAddress {
  fullAddress?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  latitude?: number;
  longitude?: number;
  googlePlaceId?: string;
}
const getTheStreetAddress = (addr: AddressComponentMapType) => {
  return [addr.street_number?.long_name, addr.route?.long_name].join(' ');
};
const getFullAddress = (
  result: google.maps.places.PlaceResult
): FullAddress => {
  const addressComponentsRecord = addrComponentsToRecord(
    result.address_components
  );
  const latLng = result.geometry?.location?.toJSON();
  const latitude = latLng?.lat ? Number(latLng.lat.toFixed(7)) : undefined;
  const longitude = latLng?.lng ? Number(latLng.lng.toFixed(7)) : undefined;
  return {
    fullAddress: result.formatted_address,
    streetAddress: getTheStreetAddress(addressComponentsRecord),
    city: addressComponentsRecord[AddressComponentType.LOCALITY]?.long_name,
    state:
      addressComponentsRecord[AddressComponentType.ADMINISTRATIVE_AREA_LEVEL_1]
        ?.short_name,
    postalCode:
      addressComponentsRecord[AddressComponentType.POSTAL_CODE]?.long_name,
    latitude,
    longitude,
    googlePlaceId: result.place_id,
  };
};
export const AddressLookup: FC<{
  sx?: SxProps<Theme>;
  onChange?: (addr: FullAddress | null) => void;
  error?: string;
  initialValue?: { addressString?: string; googlePlaceId?: string }; // placeId
  readonly?: boolean;
  placeholder: string;
  name?: string;
}> = ({ placeholder, initialValue, readonly, sx, onChange, error }) => {
  const [place, setMapPlace] = useState<
    google.maps.places.PlaceResult | undefined
  >();
  const resetHandlerRef = useRef({ reset: () => {} });
  const getGeocoderResult = useGeocoder();
  const handleAutocomplete = (
    result: google.maps.places.PlaceResult | null,
    setMap: boolean = true
  ) => {
    const fullAddress = result ? getFullAddress(result) : null;
    onChange?.(fullAddress);
    if (!result) resetHandlerRef.current.reset();
    if (setMap && result) setMapPlace(result);
  };
  const handleAutocompleteRef = useRef(handleAutocomplete);
  handleAutocompleteRef.current = handleAutocomplete;

  useEffect(() => {
    if (
      (initialValue?.googlePlaceId || initialValue?.addressString) &&
      getGeocoderResult
    ) {
      void getGeocoderResult({
        placeId: initialValue.googlePlaceId,
        address: initialValue.googlePlaceId
          ? undefined
          : initialValue.addressString,
      }).then((result) => {
        const placeResult = result as google.maps.places.PlaceResult;
        if (!initialValue.googlePlaceId && initialValue.addressString) {
          handleAutocompleteRef.current(placeResult, false);
        }
        setMapPlace(placeResult);
      });
    }
  }, [
    getGeocoderResult,
    initialValue?.addressString,
    initialValue?.googlePlaceId,
  ]);

  return (
    <>
      <Stack sx={sx}>
        {!readonly && (
          <Box sx={{ pb: { xs: 24 } }}>
            <GoogleAutocomplete
              onChange={handleAutocomplete}
              error={error}
              placeholder={placeholder}
              value={place?.formatted_address ?? initialValue?.addressString}
            />
          </Box>
        )}
        <GoogleMap place={place} innerRef={resetHandlerRef} />
      </Stack>
    </>
  );
};
