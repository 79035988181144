import { css as _, IconButton, styled } from '@mui/material';
// in accordance with https://www.figma.com/file/SXWATb7HLZFlMiuuB6ZIJL/Vivica-UI-Kit?type=design&node-id=8612-43650&t=g1ceGhp9nUVLY9QM-4
export const GrayishIconButton = styled(IconButton)(
  ({ theme: t }) => _`
    path {
      fill: ${t.palette.grey[300]};
    }

    @media (hover: hover) {
      &:hover {
        path {
          fill: ${t.palette.grey[500]};
        }
      }
    }

    &:active {
      path {
        fill: ${t.palette.grey[700]}
      }
    }

    &:disabled {
      path {
        fill: ${t.palette.grey[200]};
      }
    }
  `
);
