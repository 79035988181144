/** @jsxImportSource @emotion/react */
import { PinCodeControl } from '@/components/PinCodeControl';
import { Button } from '@/components/Button';
import { Link, Text } from '@/components/Text';
import { FormikProps, Formik } from 'formik';
import { useTranslate } from '@/i18n/useTranslate';
import React, { useMemo, FC, useRef, useCallback, useEffect } from 'react';
import {
  useChangePasswordRequest,
  usePinCodeRequest,
  usePinResend,
} from '@/store/profile/apiHooks';
import * as yup from 'yup';
import { useRecaptcha } from '@/hooks/useRecaptcha';
import { useMinutesCountDown } from '@/hooks/useMinutesCountdown';
import { useInit } from '@/hooks/useInit';
import { BoxBody } from '@/components/WhiteBox';
import { useMQuery } from '@/hooks/useMQuery';
import { Box, Stack, Typography } from '@mui/material';
import { AppRoutes } from '@/routes/appRoutes';
import { useNavigate } from 'react-router-dom';
import { RecaptchaNote } from '@/components/RecaptchaNote';

import { INPUT_TIP_HEIGHT } from '@/components/common/Common';
import { useTranslatedErrors } from '@/hooks/useConnectServerValidationErrors';

const usePinValidationSchema = () => {
  const { t } = useTranslate('common');
  return useMemo(() => {
    return yup.object().shape({
      code: yup.array().test('Pin validation', function (this: any, value) {
        const { path, createError } = this;
        if (!value || value.join('').length === 0) {
          return createError({ path, message: t('FIELD_IS_REQUIRED') });
        }
        if (value.join('').length < 6) {
          return createError({ path, message: t('CODE_VALIDATION_ERROR') });
        }
        return true;
      }),
    });
  }, [t]);
};
interface PinCodeFormState {
  code: string[];
}
const pinInitialState = {
  code: [],
};

export const PinCodeForm: FC<{ next: (failed?: boolean) => void }> = ({
  next,
}) => {
  const { t } = useTranslate('profile');
  const schema = usePinValidationSchema();
  const getCaptcha = useRecaptcha();
  const { responseData: passwdResponseData, reset: resetPasswordData } =
    useChangePasswordRequest();
  const {
    sendData: resendPin,
    responseData: resendResponseData,
    reset: resetPinData,
  } = usePinResend();
  const resendTime =
    resendResponseData?.resendTime ?? passwdResponseData?.resendTime ?? 0;
  const time = useMinutesCountDown(resendTime);
  const { sendData, validationErrors, fetching, success, reset } =
    usePinCodeRequest();
  const translatedErrors = useTranslatedErrors(validationErrors?.fieldErrors);
  const formikRef = useRef<FormikProps<PinCodeFormState>>(null);
  const { mobile, tablet, desktop } = useMQuery();
  const navigate = useNavigate();
  const handleResendPin = () => {
    resendPin();
    void formikRef.current?.resetForm();
  };
  const handleSubmit = useCallback(
    async (values: PinCodeFormState) => {
      reset();
      const recaptcha = await getCaptcha();
      sendData({ code: values.code.join(''), recaptcha });
    },
    [getCaptcha, reset, sendData]
  );

  useInit(() => {
    reset();
    resetPinData();
  });
  // set formik fetching false if fetching state changed from true to false
  useEffect(() => {
    const f = fetching;
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      f && formikRef.current?.setSubmitting(false);
    };
  }, [fetching]);

  useEffect(() => {
    if (success) {
      next();
      reset();
      resetPinData();
      resetPasswordData();
    }
    if ((validationErrors?.count ?? 0) > 3) {
      reset();
      resetPasswordData();
      next(true);
    }
  }, [
    next,
    reset,
    resetPasswordData,
    resetPinData,
    success,
    validationErrors?.count,
  ]);

  useEffect(() => {
    if (translatedErrors)
      formikRef.current?.setErrors({
        ...formikRef.current?.errors,
        ...translatedErrors,
      });
    if (validationErrors?.error) {
      formikRef.current?.setFieldError('code', t(validationErrors.error));
    }
  }, [t, translatedErrors, validationErrors?.error]);

  return (
    <>
      <Formik<PinCodeFormState>
        innerRef={formikRef}
        initialValues={pinInitialState}
        onSubmit={handleSubmit}
        validateOnChange={true}
        validationSchema={schema}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
          dirty,
          isSubmitting,
          isValid,
        }) => {
          return (
            <>
              <BoxBody>
                <Typography
                  component={'p'}
                  sx={{
                    fontSize: { xs: 14 },
                    lineHeight: { xs: '20px' },
                    fontWeight: 400,
                    mb: { xs: 36, sm: 48, lg: 48 },
                  }}
                >
                  {t(
                    'A_6-DIGIT_PIN_CODE_HAS_BEEN_SENT_TO_YOUR_PHONE_PLEASE_ENTER_IT_IN_THE_FIELD_BELOW'
                  )}
                </Typography>

                <Stack
                  component={'form'}
                  id={'password_change_form'}
                  gap={{ xs: 30, sm: 0, lg: 36 - INPUT_TIP_HEIGHT }}
                  sx={{ maxWidth: 340 }}
                  onSubmit={handleSubmit}
                >
                  <PinCodeControl
                    sx={{
                      mb: { xs: 0, sm: 48 - INPUT_TIP_HEIGHT, lg: 0 },
                    }}
                    length={6}
                    name="code"
                    value={values.code}
                    error={touched.code ? (errors.code as string) : ''}
                    onChange={handleChange}
                    fullWidth
                    label={t('PIN_CODE')}
                    compact={mobile}
                  />
                  {!mobile &&
                    (time ? (
                      <Text lh={20} fz={14}>
                        {t('THE_CODE_CAN_BE_RESENT_AFTER')}&nbsp;{time}
                      </Text>
                    ) : (
                      <Link to="" onClick={handleResendPin} fz={14} lh={20}>
                        {t('RESEND_PIN_CODE')}
                      </Link>
                    ))}
                </Stack>
                <RecaptchaNote
                  mt={{ xs: 36 - INPUT_TIP_HEIGHT, sm: 130, lg: 72 }}
                />
              </BoxBody>
              <Stack
                gap={10}
                width={'100%'}
                direction={{ xs: 'column', sm: 'row-reverse' }}
                justifyContent={'space-between'}
                pt={{ xs: 0, sm: 36 }}
                alignItems={{ xs: 'initial', sm: 'center' }}
              >
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting || !isValid || !dirty}
                  form="password_change_form"
                >
                  {t('SEND')}
                </Button>
                {desktop && (
                  <Typography variant={'16_20_500'}>
                    {t('AFTER_CHANGING_THE_PASSWORD_THE_ACCOUNT_WILL_BE_')}
                  </Typography>
                )}
                {tablet && (
                  <Button
                    color={'primary'}
                    variant={'outlined'}
                    onClick={() => navigate(AppRoutes.PROFILE)}
                  >
                    {t('CANCEL')}
                  </Button>
                )}
                {mobile &&
                  (time ? (
                    <Box
                      sx={(t) => ({
                        height: 42,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 14,
                      })}
                    >
                      {t('THE_CODE_CAN_BE_RESENT_AFTER')}&nbsp;{time}
                    </Box>
                  ) : (
                    <Button variant="outlined" onClick={handleResendPin}>
                      {t('RESEND_PIN_CODE')}
                    </Button>
                  ))}
              </Stack>
            </>
          );
        }}
      </Formik>
    </>
  );
};
