import React, {
  FC,
  MouseEventHandler,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { TKeys, useTranslate } from '@/i18n/useTranslate';
import { Text, Link } from '@/components/Text';
import { PinCodeControl } from '@/components/PinCodeControl';
import { Button } from '@/components/Button';
import { useAuthState } from '@/store/auth/hooks';
import { useDispatch } from 'react-redux';
import { authActions } from '@/store/auth/slice';
import { Space, spacesToFormsMap } from '../../types';
import { useRecaptcha } from '@/hooks/useRecaptcha';
import { useMinutesCountDown } from '@/hooks/useMinutesCountdown';
import { useSearchParams } from 'react-router-dom';
import { RecaptchaNote } from '@/components/RecaptchaNote';
import { AppRoutes } from '@/routes/appRoutes';
import { Box, Stack, styled, Typography } from '@mui/material';
import { SecureTextDisplay } from './SecureTextDisplay';
import { AuthHeader } from '@/features/authentication/common/AuthHeader';
import { ValidationErrorType } from '@/types/ValidationError';
import { useBreakpoints } from 'ui-kit';

const useResendTime = () => {
  const { pinCodeResendTime } = useAuthState();
  return useMinutesCountDown(pinCodeResendTime);
};
export const LinkContainer = styled('div')({
  height: 20,
  width: '100%',
  textAlign: 'center',
});
export const PinCodeFragment: FC<{
  space: Space;
  sendBy?: 'email' | 'phone';
}> = ({ space, sendBy }) => {
  const b = useBreakpoints();
  const [pin, setPin] = useState<string[]>([]);
  const { t } = useTranslate('auth');
  const [searchParams] = useSearchParams();
  const time = useResendTime();
  const {
    forms,
    fetchingDeprecated: fetching,
    serverValidation,
  } = useAuthState();
  const phone = forms[spacesToFormsMap[space]]?.phone ?? '';
  const email =
    (forms[spacesToFormsMap[space]] as { email?: string })?.email ?? '';
  const dispatch = useDispatch();
  useRecaptcha();
  const handlePinChange = useCallback(
    ({ target: { value } }: { target: { name?: string; value: string[] } }) => {
      if (serverValidation.errors.CODE_VALIDATION_ERROR) {
        dispatch(
          authActions.resetValidationError(
            ValidationErrorType.CODE_VALIDATION_ERROR
          )
        );
      }
      setPin(value);
    },
    [dispatch, serverValidation.errors.CODE_VALIDATION_ERROR]
  );
  const handleResendCode: MouseEventHandler<HTMLAnchorElement> =
    useCallback(() => {
      dispatch(authActions.requestNewPinCode(space));
      setPin(Array(6).fill(''));
    }, [dispatch, space]);
  const handleSendPinCode: React.FormEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      let activationCode;
      if (space === Space.Activation) {
        activationCode = searchParams.get('code') ?? '';
      }
      dispatch(
        authActions.sendCode({
          code: pin.join(''),
          space,
          activationCode,
        })
      );
    },
    [dispatch, pin, searchParams, space]
  );

  useLayoutEffect(() => {
    if ((serverValidation?.attempts?.CODE_VALIDATION_ERROR ?? 0) > 3) {
      dispatch(authActions.goToStep('pin-code-attempts-expired'));
    }
  }, [dispatch, serverValidation?.attempts?.CODE_VALIDATION_ERROR]);

  useEffect(() => {
    window.document.querySelectorAll('input')[0]?.focus();
  }, []);

  let headerText = '';
  let link = <></>;
  let descriptionText: TKeys<'auth'> = 'WE_SENT_A_PIN_CODE_VIA_SMS_TO';

  switch (space) {
    case Space.Registration:
      headerText = t('REGISTER');
      link = (
        <Link to={{ pathname: AppRoutes.AUTH, search: window.location.search }}>
          {t('SIGN_IN')}
        </Link>
      );
      break;
    case Space.Login:
    case Space.Mfa:
      headerText = t('SIGN_IN');
      link = (
        <Link
          to={{
            pathname: AppRoutes.REGISTRATION,
            search: window.location.search,
          }}
        >
          {t('REGISTER')}
        </Link>
      );
      break;
    case Space.Recovery:
      headerText = t('ACCOUNT_RECOVERY');
      descriptionText =
        sendBy === 'phone'
          ? descriptionText
          : 'WE_SENT_A_PIN_CODE_VIA_EMAIL_TO';
      link = (
        <Link to={{ pathname: AppRoutes.AUTH, search: window.location.search }}>
          {t('SIGN_IN')}
        </Link>
      );
      break;
    case Space.Activation:
      headerText = t('REGISTER');
      link = (
        <Link to={{ pathname: AppRoutes.AUTH, search: window.location.search }}>
          {t('SIGN_IN')}
        </Link>
      );
      break;
  }

  const pinHandler = () => (
    <LinkContainer sx={{ textAlign: { xs: 'center' }, mb: { xs: 48, sm: 36 } }}>
      {time ? (
        <Text lh={20} fz={14}>
          {t('THE_CODE_CAN_BE_RESENT_AFTER')}&nbsp;{time}
        </Text>
      ) : (
        <Link to="" onClick={handleResendCode} fz={14} lh={20}>
          {t('RESEND_PIN_CODE')}
        </Link>
      )}
    </LinkContainer>
  );
  const isMfa = space === 'mfa';

  const renderError = () =>
    serverValidation.errors.CODE_VALIDATION_ERROR
      ? isMfa
        ? t('AUTHENTICATION_CODE_DOES_NOT_MATCH')
        : t('PIN_CODE_IS_INCORRECT_YOU_HAVE_ATTEMPTS', {
            attempts:
              4 - (serverValidation?.attempts?.CODE_VALIDATION_ERROR ?? 0),
          })
      : '';

  const commonDescription = () => {
    return (
      <>
        {t(descriptionText)}&nbsp;
        <Typography
          component={'span'}
          sx={{
            whiteSpace: 'nowrap',
          }}
        >
          {sendBy === 'email' ? (
            `${email.split('@')[0] || ''}@****.***`
          ) : (
            <SecureTextDisplay first={4} last={3}>
              {phone}
            </SecureTextDisplay>
          )}
        </Typography>
        . &nbsp;
        {t('PLEASE_ENTER_IT_IN_THIS_FIELD')}
      </>
    );
  };
  const mfaDescription = t('OPEN_YOUR_AUTHENTICATION_APP_');
  const description = isMfa ? mfaDescription : commonDescription();

  const error = renderError();

  return (
    <Stack width={'100%'} justifyContent={'space-between'}>
      <Stack width={'100%'} sx={{ flex: { xs: '1 1 auto' } }}>
        <AuthHeader
          title={headerText}
          sx={{ mb: { sm: 240, lg: 215, xs: 60 } }}
        >
          {link}
        </AuthHeader>
        <Box sx={{ mb: { xs: 36, sm: 48, lg: 32 } }}>
          <Typography sx={styles.description}>{description}</Typography>
        </Box>
        <Stack
          component={'form'}
          onSubmit={handleSendPinCode}
          id={'pin_form'}
          sx={{ flex: { xs: '1 1 auto' } }}
        >
          <PinCodeControl
            sx={{
              mb: {
                xs: 24,
                sm: 96,
                lg: 80,
              },
            }}
            length={6}
            value={pin}
            label={isMfa ? t('ONE_TIME_PASSWORD') : t('PIN_CODE')}
            compact={b.xsOnly}
            error={error}
            onChange={handlePinChange}
          />
        </Stack>
        <Button
          form={'pin_form'}
          fullWidth
          disabled={pin.join('').length !== 6 || fetching}
          color={'secondary'}
          sx={{ mb: { xs: 24, sm: 12 } }}
          type={'submit'}
        >
          {t('CONFIRM')}
        </Button>
        {!fetching && !isMfa && pinHandler()}
      </Stack>
      <RecaptchaNote />
    </Stack>
  );
};

const styles = {
  description: {
    display: { xs: 'inline', lg: 'block' },
    typography: { xs: '14_18_500', sm: '16_24_400', md: '16_20_500' },
    '*': { typography: { xs: '14_18_500', sm: '16_24_400', md: '16_20_500' } },
  },
};
