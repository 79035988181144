import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '@/api/client/ApiClient';
import { showError500OrUnknownToast } from '@/store/common/showError500OrUnknownToast';
import { AccountTOTP } from '@/api/__generated__/webApi';
import { ThunkApiType } from '@/store/common/types/ThunkApiType';
import { getErrors } from '@/store/common/error-handlers';

export const getQrs = createAsyncThunk('profile/qrs', async (_, thunkAPI) => {
  try {
    const { data } = await client.getAccountTotp();
    return thunkAPI.fulfillWithValue(data);
  } catch (e) {
    showError500OrUnknownToast(e);
    const errors = getErrors(e);
    return thunkAPI.rejectWithValue(errors);
  }
});

export const createQr = createAsyncThunk<
  AccountTOTP,
  { description: string },
  ThunkApiType
>('profile/create-qr', async ({ description }, thunkAPI) => {
  try {
    const { data } = await client.createAccountTotp({ description });
    return thunkAPI.fulfillWithValue(data);
  } catch (e) {
    const errors = getErrors(e);
    if (errors.common) {
      showError500OrUnknownToast(e);
    }
    return thunkAPI.rejectWithValue(errors);
  }
});

export const updateQr = createAsyncThunk<
  void,
  { description: string },
  ThunkApiType
>('profile/update-qr', async ({ description }, thunkAPI) => {
  const id = thunkAPI.getState().qrState.selectedQrId;
  try {
    if (!id) throw new Error('NO Id');
    await client.updateAccountTotpDescription({ description, id });
  } catch (e) {
    const errors = getErrors(e);
    if (errors.common) {
      showError500OrUnknownToast(e);
    }
    return thunkAPI.rejectWithValue(errors);
  }
});

export const deleteQr = createAsyncThunk<void, void, ThunkApiType>(
  'profile/delete-qr',
  async (_, thunkAPI) => {
    const id = thunkAPI.getState().qrState.selectedQrId;
    try {
      if (!id) throw new Error('No Id');
      await client.deleteAccountTotp(id);
    } catch (e) {
      showError500OrUnknownToast(e);
    }
  }
);
