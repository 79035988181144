import { useTranslate } from '@/i18n/useTranslate';
import React, { FC, ReactNode, useMemo } from 'react';
import * as yup from 'yup';
import { FormikProps } from 'formik';
import { UpdateAddressForm } from '@/features/profile-address/types';
import { FullAddress } from '@/components/AddressLookup/AddressLookup';
import { useUpdateAddress } from '@/features/profile-address/store/hooks';
import { FormikRedux } from '@/components';
import { getErrorForDisplay } from '@/components/FormikRedux/useFormikError';
import { createEvent } from '@/utils/createEvent';

const useValidationSchema = () => {
  const { t } = useTranslate('profile');
  return useMemo(() => {
    return yup.object().shape({
      fullAddress: yup
        .object()
        .nullable()
        .required(t('FIELD_IS_REQUIRED'))
        .test(
          'full-address',
          t('PLEASE_CLARIFY_THE_ADDRESS'),
          (addr: FullAddress | null) => {
            if (!addr?.city?.trim() || !addr?.streetAddress?.trim()) {
              return false;
            }
            return true;
          }
        ),
    });
  }, [t]);
};

export const FormLogic: FC<{
  children: (
    formikProps: FormikProps<UpdateAddressForm>,
    fullAddressError: string | undefined,
    onChangeFullAddress: (addr: FullAddress | null) => void
  ) => ReactNode;
}> = ({ children }) => {
  const d = useUpdateAddress();
  const schema = useValidationSchema();

  return (
    <FormikRedux<UpdateAddressForm>
      noForm
      initialValues={d.values}
      onSubmit={d.submit}
      serverErrors={d.errors}
      resetServerErrors={d.resetServerErrors}
      validationSchema={schema}
      validateOnChange
    >
      {(props) => {
        const fullAddressError = getErrorForDisplay({
          error:
            (props.errors.fullAddress as { streetAddress?: string })
              ?.streetAddress ?? props.errors.fullAddress,
          value: props.values.fullAddress?.streetAddress,
          touched: props.touched.fullAddress ?? false,
          submitCount: props.submitCount,
        });
        const cb = (addr: FullAddress | null) => {
          props.handleChange(
            createEvent({
              name: 'fullAddress',
              value: addr,
            })
          );
        };
        return children(props, fullAddressError, cb);
      }}
    </FormikRedux>
  );
};
