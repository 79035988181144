/** @jsxImportSource @emotion/react */
import { useTranslate } from '@/i18n/useTranslate';
import React, { FC, useCallback, useState } from 'react';
import { PinCodeForm } from './fragments/PinCodeForm';
import { PasswordForm } from './fragments/PasswordForm';
import { WhiteBox } from '@/components/WhiteBox';
import { useNavigate } from 'react-router-dom';
import { css, Typography } from '@mui/material';
import { DialogAlert } from '@/components';
import { AppRoutes } from '@/routes/appRoutes';
export const Password: FC = () => {
  const { t } = useTranslate('profile');
  const navigate = useNavigate();
  const [step, setStep] = useState<'set-password' | 'send-pincode' | 'success'>(
    'set-password'
  );
  const [pinFailed, setPinFailed] = useState(false);
  const goToPin = useCallback(() => {
    setStep('send-pincode');
  }, []);

  const goTo = useCallback((failed?: boolean) => {
    if (failed) {
      setPinFailed(true);
      setStep('set-password');
    } else {
      setStep('success');
    }
  }, []);

  let form = null;

  switch (step) {
    case 'set-password':
      form = <PasswordForm next={goToPin} />;
      break;
    case 'success':
    case 'send-pincode':
      form = <PinCodeForm next={goTo} />;
      break;
  }

  return (
    <>
      <div
        css={css`
          height: 100%;
          display: flex;
          flex-direction: column;
          & > section {
            height: 100%;
          }
        `}
      >
        <WhiteBox>
          <Typography variant={'h2'} sx={{ mb: { xs: 36, sm: 48 } }}>
            {t('CHANGE_PASSWORD')}
          </Typography>
          {form}
        </WhiteBox>
      </div>
      <DialogAlert
        open={step === 'success'}
        title={t('SUCCESS')}
        type={'success'}
        onClose={() =>
          navigate({
            pathname: AppRoutes.PROFILE,
            search: window.location.search,
          })
        }
      >
        {t('CONGRATULATIONS_YOUR_PASSWORD_HAS_BEEN_CHANGED')}
      </DialogAlert>
      <DialogAlert
        open={pinFailed}
        title={t('ERROR')}
        type={'error'}
        onClose={() => {
          setStep('set-password');
          setPinFailed(false);
        }}
      >
        {t('WRONG_PIN_ENTERED_PLEASE_TRY_AGAIN')}
      </DialogAlert>
    </>
  );
};
