import { getRecaptchaToken } from '@/hooks/useRecaptcha';
import { useCallback } from 'react';
import { QueryData } from '@/store/query/slice';
import { useMutationQuery, useQuery } from '@/store/query/useQuery';
import { AccountDocument } from '@/types';

export const WebApiEndpoints = {
  PIN_CODE_RESEND: '/api/code/send',
  DOCUMENTS: '/api/account/contact/documents',
  ACCOUNT_CHANGE_PASSWORD: '/api/account/change-password',
  ACCOUNT_CHANGE_CONFIRM_PASSWORD: '/api/account/confirm/change-password',
} as const;

export const useDocuments = (): QueryData<AccountDocument[]> => {
  const data = useQuery({ path: WebApiEndpoints.DOCUMENTS });
  return data ?? {};
};
export const useChangePasswordRequest = () => {
  return useMutationQuery<
    { currentPassword: string; newPassword: string },
    { resendTime: number }
  >({
    method: 'PATCH',
    path: WebApiEndpoints.ACCOUNT_CHANGE_PASSWORD,
  });
};
export const usePinCodeRequest = () => {
  return useMutationQuery<{ code: string; recaptcha: string }>({
    method: 'POST',
    path: WebApiEndpoints.ACCOUNT_CHANGE_CONFIRM_PASSWORD,
  });
};
export const usePinResend = () => {
  const { sendData, ...restData } = useMutationQuery<
    { codeType: 'CHANGE_PASSWORD'; recaptcha: string },
    { resendTime: number }
  >({
    method: 'POST',
    path: WebApiEndpoints.PIN_CODE_RESEND,
  });
  const sendData1 = useCallback(() => {
    void getRecaptchaToken().then((recaptcha) => {
      sendData({ codeType: 'CHANGE_PASSWORD', recaptcha });
    });
  }, [sendData]);
  return { ...restData, sendData: sendData1 };
};
