import { FC, useEffect, useRef, useState } from 'react';
import { UUID } from '@/types';
import { CompanyPermission } from '@/api/__generated__/webApi';
import 'users-control-frontend';
const USERS_CONTROL_EVENT = 'user-control-event';

export type CustomEventDetails =
  | { type: 'NOTIFY_SUCCESS'; text: string; data: any }
  | { type: 'NOTIFY_ERROR'; text: string }
  | { type: 'WRONG_PERMISSIONS' };
export type XModuleType = 'PASSPORT' | 'LIS' | 'PROFESSIONAL';

export interface UsersControlProps {
  companyId: UUID | undefined;
  accountId: UUID | undefined;
  permissions?: CompanyPermission;
  onEvent?: (e: CustomEvent<CustomEventDetails>, companyId: UUID) => void;
  xModuleType: XModuleType;
}
export const UsersControlReact: FC<UsersControlProps> = ({
  companyId,
  accountId,
  permissions,
  onEvent,
  xModuleType,
}) => {
  const [el, setEl] = useState<HTMLElement | null>(null);

  const ref = useRef({
    onEvent,
    companyId,
  });
  ref.current.onEvent = onEvent;
  ref.current.companyId = companyId;

  useEffect(() => {
    if (!el) {
      return;
    }
    const cb = (e: CustomEvent<CustomEventDetails>) => {
      ref.current.onEvent?.(e, ref.current.companyId as string);
    };

    el.addEventListener(USERS_CONTROL_EVENT, cb as any);
    return () => el.removeEventListener(USERS_CONTROL_EVENT, cb as any);
  }, [el]);

  return (
    <users-control
      ref={(el) => setEl(el)}
      company-id={companyId}
      account-id={accountId}
      permissions={permissions ? JSON.stringify(permissions) : undefined}
      x-module-type={xModuleType}
    />
  );
};
