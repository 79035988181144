import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { companyNpiActions as actions, CompanyNPIState } from './slice';
import { useNPIValidate } from '@/store/npi-individual/hooks';
import { ValidationErrorType } from '@/types/ValidationError';

export const useNPIStateOrg = () =>
  useSelector<RootState, CompanyNPIState>((state) => state.companyNpi);

export const useOrgNPI = () => {
  const state = useNPIStateOrg();
  const dispatch = useDispatch();

  const callbacks = useMemo(() => {
    return {
      requestNPI: (npi: string, parent?: boolean) => {
        dispatch(actions.requestNPIInfo({ npi, parent }));
      },
      resetNPIInfo: () => {
        dispatch(actions.reset());
      },
      resetRequestSuccess: () => dispatch(actions.setRequestSuccess(false)),
    };
  }, [dispatch]);

  return {
    id: state.npiNumber,
    fetching: state.fetching,
    step: state.step,
    npi: state.npi,
    parentNpiId: state.parentNpiNumber,
    parentNPI: state.parentNPI,
    commonError: state.commonError,
    requestSent: state.requestSuccess,
    ...callbacks,
  };
};
const NPI_VALID_LENGTH = 10;
const validateDomainValue = (
  value: string,
  validateLastDash?: boolean
): ValidationErrorType | null => {
  if (!value.length) {
    return null;
  }

  if (/^-/.test(value)) {
    return ValidationErrorType.INVALID_DOMAIN;
  }

  const minLengthIsValid = value.length > 3;

  if (!minLengthIsValid) {
    return ValidationErrorType.NAME_IS_TOO_SHORT;
  }

  const maxLengthIsValid = value.length <= NPI_VALID_LENGTH;

  if (!maxLengthIsValid) {
    return ValidationErrorType.INVALID_DOMAIN;
  }

  if (
    (validateLastDash || value.length === NPI_VALID_LENGTH) &&
    /-$/.test(value)
  ) {
    return ValidationErrorType.INVALID_DOMAIN;
  }

  return !/[^a-z\d-]+$/g.test(value)
    ? null
    : ValidationErrorType.INVALID_DOMAIN;
};
const sanitizeDomainValue = (value: string) => {
  return value.replace(/[^a-z\d-]/g, '').slice(0, 10);
};

export const useCompanyNPIForm = (parent?: boolean) => {
  const { dirty, error, validate, setDirty, resetForm } = useNPIValidate();
  const [npiValue, setNpiValue] = useState('');
  const state = useNPIStateOrg();
  const [domainValue, setDomainValue] = useState(state.npiNumber);
  const [validationError, setValidationError] =
    useState<ValidationErrorType | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (state.npiNumber) {
      setDomainValue(state.npiNumber);
    }
  }, [state.npiNumber]);

  const callbacks = useMemo(() => {
    return {
      handleSubmitNPI: () => {
        if (error && dirty) {
          return;
        }
        setDirty();
        setValidationError(null);
        if (state.errors) {
          dispatch(actions.resetErrors());
        }
        if (error) {
          return;
        }
        if (npiValue) {
          dispatch(actions.requestNPIInfo({ npi: npiValue, parent }));
        }
      },
      handleChangeNPI: ({
        target: { value },
      }: React.ChangeEvent<HTMLInputElement>) => {
        setValidationError(null);
        if (state.errors) {
          dispatch(actions.resetErrors());
        }
        if (!value?.length) {
          resetForm();
        } else {
          validate(value);
        }
        setNpiValue(value);
      },
      handleSubmitDomain: () => {
        setValidationError(null);
        if (state.errors) {
          dispatch(actions.resetErrors());
        }
        const domain = domainValue?.trim().toLowerCase() ?? '';
        const validationError = validateDomainValue(domain, true);
        if (validationError) {
          setValidationError(validationError);
          return;
        }
        dispatch(
          actions.requestConfirm({
            thDomain: domain || (state.npiNumber ?? ''),
          })
        );
      },
      handleChangeDomain: ({
        target: { value },
      }: ChangeEvent<HTMLInputElement>) => {
        setValidationError(null);
        if (state.errors) {
          dispatch(actions.resetErrors());
        }
        const domain = sanitizeDomainValue(value);
        const validationError = validateDomainValue(domain);
        if (validationError) {
          setValidationError(validationError);
        }
        setDomainValue(domain);
      },
    };
  }, [
    dirty,
    dispatch,
    domainValue,
    error,
    npiValue,
    parent,
    resetForm,
    setDirty,
    state.errors,
    state.npiNumber,
    validate,
  ]);

  return {
    error:
      error || state.errors?.npi || state.errors?.thDomain || validationError,
    fetching: state.fetching,
    npiNumber: state.npiNumber,
    domainValue,
    dirty,
    ...callbacks,
  };
};
