/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { CompanyPermissionName, Role } from '@/types';
import {
  Box,
  css as _,
  Stack,
  styled,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { Button } from '@/components/Button';
import { useTranslate } from '@/i18n/useTranslate';
import { ReactComponent as IconSVGKey } from '@/images/icons/icon_key.svg';
import { ReactComponent as IconSVGMenuPlaceholder } from '@/images/icons/icon_menu_placeholder.svg';
import { PermissionsKeeper } from '@/store/permissions/PermissionsKeeper';
import { useNavigate } from 'react-router';
import { ReactComponent as IconPencil } from '@/images/icons/icon_pencil.svg';

import { GrayishIconButton } from '@/components/GrayButton/GrayIconButton';
export const RoleItem: FC<{
  role?: Role;
  className?: string;
  sx?: SxProps<Theme>;
  onEdit?: () => void;
  disableEdit?: boolean;
}> = ({ disableEdit, onEdit, role, className, sx }) => {
  const { t } = useTranslate('companies');
  const navigate = useNavigate();
  if (!role) {
    return null;
  }

  return (
    <Box component={'section'} sx={sx} className={className}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        mb={{ xs: 12, sm: 12, lg: 4 }}
        gap={6}
      >
        <Typography
          variant={'16_24_700'}
          maxWidth={{ xs: 'initial', lg: 760 }}
          sx={{ wordBreak: 'break-word' }}
        >
          {role.name}
        </Typography>
        {onEdit && (
          <GrayishIconButton onClick={onEdit} disabled={disableEdit}>
            <IconPencil css={{ width: '16px', height: 'auto' }} />
          </GrayishIconButton>
        )}
      </Stack>
      <Typography
        variant={'12_18_500'}
        mb={{ xs: 24, sm: 24, lg: 18 }}
        maxWidth={{ xs: 'initial', lg: 760 }}
        display={'block'}
        sx={{ wordBreak: 'break-word' }}
      >
        {role.description}
      </Typography>
      <nav
        css={(t) => _`
          display: inline-flex;
          align-items: center; 
          gap: 44px; 
          ${t.breakpoints.only('mobile')} {
            justify-content: space-between; 
            display: flex;
            width: 100%
          }
        `}
      >
        <TextButton
          onClick={() => {
            navigate({
              pathname: `./permissions/${role.id}${
                role.editable ? '/editable' : ''
              }`,
              search: window.location.search,
            });
          }}
          startIcon={
            <IconSVGKey
              width={22}
              height={22}
              css={(t) =>
                _`path {fill: ${t.palette.secondary.main}}; flex: 0 0 auto;`
              }
            />
          }
        >
          {t('PERMISSIONS')}
        </TextButton>
        <PermissionsKeeper
          show={(has) =>
            has(CompanyPermissionName.CompanyAssignRole) ||
            has(CompanyPermissionName.CompanyRevokeRole) ||
            has(CompanyPermissionName.CompanyViewAllUsers)
          }
        >
          <TextButton
            onClick={() => {
              navigate({
                pathname: `./users/${role.id}`,
                search: window.location.search,
              });
            }}
            startIcon={<IconSVGMenuPlaceholder width={20} />}
          >
            {t('USERS_LIST')}
          </TextButton>
        </PermissionsKeeper>
      </nav>
    </Box>
  );
};

const TextButton = styled(Button)({});
TextButton.defaultProps = {
  sx: {
    paddingX: 10,
    marginX: -10,
  },
  variant: 'text',
  color: 'secondary',
};
