import React, { FC } from 'react';
import { useTranslate } from '@/i18n/useTranslate';
import { Button, useBreakpoints } from 'ui-kit';
import { useUpdateAddress } from '@/features/profile-address/store/hooks';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@/components';
import { Stack } from '@mui/material';
import { Form } from 'formik';
import { AddressLookup } from '@/components/AddressLookup';
import { InputControlF } from '@/components/FormikRedux';
import { FormLogic } from '@/features/profile-address/fragments/UpdateAddressDialog/FormLogic';

export const UpdateAddressDialog: FC = () => {
  const { t } = useTranslate('profile');
  const b = useBreakpoints();
  const d = useUpdateAddress();
  return (
    <Dialog
      open={d.isOpen}
      keepMounted={false}
      size={b.xsOnly ? 'sm' : b.lg ? 'xxl' : 'xl'}
      onClose={d.dismiss}
    >
      <FormLogic>
        {(formikProps, fullAddressError, faHandleChange) => {
          const { values, isSubmitting } = formikProps;
          return (
            <>
              <DialogTitle onClose={d.dismiss}>
                {d.edit ? t('EDIT_ADDRESS') : t('ADD_ADDRESS')}
              </DialogTitle>
              <DialogContent>
                <Stack component={Form} gap={24} id={'add_address_form'}>
                  <AddressLookup
                    error={fullAddressError}
                    placeholder={t('ENTER_YOUR_PERSONAL_ADDRESS')}
                    initialValue={{
                      googlePlaceId: values.fullAddress?.googlePlaceId,
                      addressString: values.fullAddress?.streetAddress,
                    }}
                    onChange={faHandleChange}
                  />
                  <InputControlF
                    optional
                    name={'address2'}
                    placeholder={t('ENTER_APARTMENT_SUITE_OR_UNIT')}
                    max={55}
                  />
                </Stack>
              </DialogContent>
              <DialogActions spaceBetween={b.sm}>
                <Button
                  color={'secondary'}
                  variant={'outlined'}
                  onClick={d.dismiss}
                  disabled={isSubmitting}
                >
                  {t('CANCEL')}
                </Button>
                <Button
                  color={'secondary'}
                  type={'submit'}
                  form={'add_address_form'}
                  disabled={isSubmitting}
                >
                  {t('SAVE')}
                </Button>
              </DialogActions>
            </>
          );
        }}
      </FormLogic>
    </Dialog>
  );
};
