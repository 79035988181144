import { ButtonBase, ButtonBaseProps, styled } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { alpha } from '@/theme/utils';
export const liClassname = 'li-item';
export const selectedClassName = 'active';

export const MAIN_MENU_PADDING = 27;
export const BORDER_WIDTH = 6;
export const ICON_WIDTH = 22;
export const GAP_WIDTH = 17;
interface BtProps extends ButtonBaseProps {
  header?: boolean;
  icon?: boolean;
}
export const MenuButton = styled(
  ({ header: _, icon: __, ...props }: PropsWithChildren<BtProps>) => {
    return <ButtonBase {...props} />;
  }
)<BtProps>`
  width: 100%;
  min-height: 64px;
  padding-left: ${({ header, icon }) =>
    header
      ? MAIN_MENU_PADDING - BORDER_WIDTH
      : MAIN_MENU_PADDING -
        BORDER_WIDTH +
        (icon ? 0 : ICON_WIDTH + GAP_WIDTH)}px;
  border-style: solid;
  border-left-width: ${BORDER_WIDTH}px;
  border-color: transparent;
  font-size: ${({ header }) => (header ? 16 : 16)}px;
  font-weight: ${({ header, icon }) => (header || icon ? 700 : 500)};
  line-height: ${({ header }) => (header ? 24 : 20)}px;
  background-color: transparent;
  justify-content: start;
  position: relative;
  text-align: left;
`;

export const Link = styled(
  ({
    header: _,
    ...props
  }: PropsWithChildren<{ header?: boolean } & NavLinkProps>) => {
    return <NavLink {...props} />;
  }
)<{ header?: boolean }>`
  text-decoration: none;
  &:first-of-type {
    margin-top: 0;
  }
  margin-top: ${({ header }) => (header ? 12 : 0)}px;
  * {
    color: ${({ theme }) => theme.palette.text.primary};
  }
  svg {
    fill: ${({ theme }) => theme.colors.defaultTextColor};
  }
  &:hover {
    button {
      background-color: ${({ theme }) =>
        alpha(theme.colors.all.secondary5, 60)};
      border-color: ${({ theme }) => alpha(theme.colors.secondary, 60)};
      & * {
        color: ${({ theme }) => theme.colors.secondary};
      }
      svg {
        fill: ${({ theme }) => theme.colors.secondary};
      }
    }
  }
  &:active {
    button {
      background-color: ${({ theme }) => theme.colors.all.secondary10};
      border-color: ${({ theme }) => alpha(theme.colors.secondary, 60)};
      & * {
        color: ${({ theme }) => theme.colors.secondary};
      }
    }
  }
  &.${selectedClassName} {
    button {
      background-color: ${({ theme }) => theme.colors.all.secondary5};
      border-color: ${({ theme }) => theme.colors.secondary};
      & * {
        color: ${({ theme }) => theme.colors.secondary};
      }
    }
  }
  button {
    &:disabled {
      * {
        color: ${({ theme }) => theme.colors.all.grey[300]};
      }
      span.dot {
        background-color: ${({ theme }) => theme.colors.all.grey[300]};
      }
    }
  }
  .mobile-layout &,
  .tablet-layout & {
    button {
      border-left-width: 0;
    }
  }
`;
export const MenuListItem = styled(Link)<{
  header?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  & > div {
    position: relative;
  }
  p {
    font-size: 12px;
    padding: 0;
    margin: 0;
    font-weight: 400;
    font-family: inherit;
    line-height: 20px;
  }
  border-top-width: 0;
  ${({ header }) => (!header ? 'border-top-width: 0 !important;' : '')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};
`;

export const MenuList = styled('nav')`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 30px;
  .mobile-layout & {
    min-width: 100%;
  }
`;

export const IconView = styled('span')`
  height: 100%;
  width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg path {
    fill: ${({ theme }) => theme.colors.all.grey2};
  }
  .${selectedClassName} & {
    svg path {
      fill: ${({ theme }) => theme.colors.secondary};
    }
  }
  .li-item:hover & {
    svg path {
      fill: ${({ theme }) => theme.colors.secondary};
    }
  }
`;
