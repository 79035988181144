import { AxiosError } from 'axios';
import { apiClient } from '@/api/client/ApiClient';
import {
  UUID,
  Address,
  RecaptchaToken,
  ResendTime,
  Phone,
  MobilePhone_,
  AccountDetails,
  Nullable,
} from '@/types';
import {
  Contact,
  Email,
  ModuleType,
  NPI1ShortData,
  NPI2ShortData,
} from '@/api/__generated__/webApi';

export const getAccountDetails = async () => {
  const response = await apiClient.get<AccountDetails>('/api/account');
  return response.data;
};

export const saveAccountContactDetails = async (contact: Nullable<Contact>) => {
  const response = await apiClient.put<Contact>(
    '/api/account/contact',
    contact
  );
  return response.data;
};

export const getAccountNpi = async (): Promise<NPI1ShortData> => {
  const response = await apiClient.get<NPI1ShortData>('/api/account/npi');
  return response.data;
};
export const checkNPIIsBeingProcessed = async (): Promise<number> => {
  const response = await apiClient.get(
    '/api/account/npi/medicare-provider/verify/registration/available'
  );
  return response.status;
};

export const requestNPIInfo = async (
  npiNumber: string
): Promise<NPI1ShortData> => {
  const response = await apiClient.post<NPI1ShortData>(
    '/api/account/npi/medicare-provider/verify/registration',
    { npi: npiNumber }
  );
  return response.data;
};

export const confirmNPIInfo = async (npiNumber: string): Promise<number> => {
  const response = await apiClient.post<NPI1ShortData>(
    '/api/account/npi/medicare-provider/confirm/registration',
    { npi: npiNumber }
  );
  return response.status;
};

export const requestCompanyNPIInfo = async (
  npiNumber: string
): Promise<NPI2ShortData> => {
  const response = await apiClient.post<NPI2ShortData>(
    '/api/account/npi/company/verify/registration',
    { npi: npiNumber }
  );
  return response.data;
};

export const requestCompanyParentNPIInfo = async (
  npiNumber: string
): Promise<NPI2ShortData> => {
  const response = await apiClient.post<NPI2ShortData>(
    '/api/account/npi/company/verify/registration',
    { npi: npiNumber }
  );
  return response.data;
};

export const confirmCompanyNPIInfo = async (data: {
  npi: string;
  parentNpi: string | null;
  thDomain: string;
}): Promise<number> => {
  const response = await apiClient.post(
    '/api/account/npi/company/confirm/registration',
    data
  );
  return response.status;
};

export const getPhones = async (): Promise<Phone[]> => {
  const response = await apiClient.get<Phone[]>('/api/account/contact/phones');
  return response.data;
};
export const getNPIPhones = async (): Promise<string[]> => {
  const response = await apiClient.get<string[]>('/api/account/npi/phones');
  return response.data;
};
export const savePhone = async (
  data: Pick<Phone, 'type' | 'phone'>
): Promise<Phone | ResendTime> => {
  const response = await apiClient
    .post<Phone>('/api/account/contact/phones', data)
    .catch((e: AxiosError) => {
      if (e.response?.status === 428 || e.response?.status === 412) {
        return e.response as { data: ResendTime };
      }
      return Promise.reject(e);
    });
  return response.data;
};
export const confirmMobilePhone = async (data: {
  code: string;
  recaptcha: string;
}): Promise<Phone> => {
  const response = await apiClient.post<Phone>(
    '/api/account/contact/phones/confirm/mobile-phone',
    data
  );
  return response.data;
};
export const deleteMobilePhone = async (id: UUID): Promise<void> => {
  void (await apiClient.delete(`/api/account/contact/phones/${id}`));
};
export const allowResultsDelivery = async ({
  id,
  allow,
}: {
  id: UUID;
  allow: boolean;
}): Promise<Phone> => {
  const response = await apiClient.patch<Phone>(
    `/api/account/contact/phones/${id}/allow-results-delivery/${String(allow)}`
  );
  return response.data;
};
export const makePhoneAsMain = async (id: UUID): Promise<Phone> => {
  const response = await apiClient.patch<Phone>(
    `/api/account/contact/phones/${id}/make-main`
  );
  return response.data;
};

export const getAllEmails = async () => {
  const response = await apiClient.get<Email[]>('/api/account/contact/emails');
  return response.data;
};

export const resendConfirmationEmail = async ({
  emailId,
  recaptcha,
}: {
  emailId: UUID;
  recaptcha: RecaptchaToken;
}) => {
  const response = await apiClient.post<ResendTime>(
    `/api/account/contact/emails/${emailId}/send-confirmation`,
    { recaptcha }
  );
  return response.data;
};

export const confirmEmail = async (
  emailId: UUID,
  confirmationToken: string
) => {
  const response = await apiClient.post<Email>(
    `/api/account/contact/emails/${emailId}/confirm/${confirmationToken}`
  );
  return response.data;
};

export const createEmail = async (email: string) => {
  const response = await apiClient.post<Email>('/api/account/contact/emails', {
    email,
  });
  return response.data;
};

export const deleteEmail = async (id: UUID) => {
  await apiClient.delete(`/api/account/contact/emails/${id}`);
};

export const allowResultsDeliveryEmail = async (id: UUID, allow: boolean) => {
  const response = await apiClient.patch<Email>(
    `/api/account/contact/emails/${id}/allow-results-delivery/${String(allow)}`
  );
  return response.data;
};

export const makeEmailAsMain = async (id: UUID) => {
  const response = await apiClient.patch<Email>(
    `/api/account/contact/emails/${id}/make-main`
  );
  return response.data;
};

export const getAddresses = async () => {
  const response = await apiClient.get<Address[]>(
    '/api/account/contact/addresses'
  );
  return response.data;
};

export const checkBackUrl = async ({ url }: { url: string }) => {
  const response = await apiClient.get<
    | { type: 'COMPANY_MODULE'; companyId: UUID; module: ModuleType }
    | { type: 'REDIRECT'; url: string }
  >('/api/account/resolve-url', { params: { url } });
  return response.data;
};

export interface InvitationDetails {
  companyName: string;
  firstName: string;
  lastName: string;
  middleName: string;
  phone: string;
}

export const confirmInvitationToModule = async (inviteToken: string) => {
  const result = await apiClient.post<InvitationDetails>(
    `/api/account/confirm/invite/${inviteToken}`
  );
  return result.data;
};

export const sendSupportMessage = async (params: {
  message: string;
  recaptcha: string;
  subject: string;
}) => {
  await apiClient.post('/api/support/send-email', params);
};

export const phoneSendConfirmation = async ({
  phoneId,
  recaptcha,
}: {
  phoneId: UUID;
  recaptcha: string;
}) => {
  const response = await apiClient.post<ResendTime>(
    `/api/account/contact/phones/${phoneId}/mobile/send-confirmation`,
    { recaptcha }
  );
  return response.data;
};

export const phoneConfirmOwnership = async ({
  phoneId,
  recaptcha,
  code,
}: {
  code: string;
  phoneId: UUID;
  recaptcha: string;
}) => {
  const response = await apiClient.patch<MobilePhone_>(
    `/api/account/contact/phones/${phoneId}/mobile/confirm-ownership`,
    { code, recaptcha }
  );
  return response.data;
};

export const fetchAvatar = async () => {
  const response = await apiClient.get<string>('/api/account/avatar');
  return response.data;
};

export const uploadAvatar = async ({ data }: { data: FormData }) => {
  await apiClient.patch('/api/account/avatar', data);
};

export * as accountApi from '.';
