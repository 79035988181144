/** @jsxImportSource @emotion/react */
import { Button } from '@/components/Button';
import { useTranslate } from '@/i18n/useTranslate';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from './Dialog';
import { Box, css, styled, SxProps, Typography, useTheme } from '@mui/material';
import { ReactComponent as IconSVGWarning } from '@/images/icons/icon_round_warning.svg';
import { ReactComponent as IconSVGSuccess } from '@/images/icons/icon_round_success.svg';
import { ReactComponent as IconSVGInfo } from '@/images/icons/icon_round_info.svg';
import { ReactComponent as IconSVGError } from '@/images/icons/icon_round_error.svg';
import { Nowrap } from '@/components/Text';
import { useMQuery } from '@/hooks/useMQuery';
export interface DialogAlertProps {
  open: boolean;
  title: string;
  type?: 'warning' | 'success' | 'info' | 'error';
  onClose: () => void;
  className?: string;
}

const AlertDialogActions = styled(DialogActions)(
  (th) => css`
    margin: 0 !important;
    padding: 24px;
  `
);
export const DialogAlert = ({
  open,
  title,
  children,
  type = 'warning',
  onClose,
  className,
}: PropsWithChildren<DialogAlertProps>) => {
  const { t } = useTranslate('common');
  const { tablet, mobile } = useMQuery();
  const theme = useTheme();
  let color: string;
  switch (type) {
    case 'success':
      color = theme.palette.success.main;
      break;
    case 'error':
      color = theme.palette.error.main;
      break;
    case 'warning':
      color = theme.palette.warning.main;
      break;
    case 'info':
      color = theme.palette.info.main;
      break;
    default:
      color = '';
  }
  return (
    <Dialog
      size={tablet || mobile ? 'xs' : 'sm'}
      open={open}
      onClose={onClose}
      className={className}
      keepMounted={false}
    >
      <DialogTitle onClose={onClose} sx={{ border: `1px solid ${color}` }}>
        <Header type={type}>{title}</Header>
      </DialogTitle>
      <DialogContent
        sx={{
          fontSize: '14px',
          textAlign: 'left !important',
          lineHeight: '20px',
          fontWeight: 500,
          border: `1px solid ${color}`,
          borderTop: 'none',
          p: '36px 24px!important',
        }}
      >
        {children}
      </DialogContent>
      <AlertDialogActions
        sx={{
          background: color,
          borderTop: 'none !important',
          pt: 16,
          pb: 16,
          justifyContent: { xs: 'center', sm: 'space-between !important' },
        }}
      >
        <Box sx={{ display: { xs: 'none', sm: 'block' } }} />
        <Button variant={'contained'} color={'white'} onClick={onClose}>
          {t('OK')}
        </Button>
      </AlertDialogActions>
    </Dialog>
  );
};

interface Props {
  children: ReactNode;
  type: 'warning' | 'success' | 'info' | 'error';
}
export const Header: FC<Props> = ({ type, children }) => {
  const styles: SxProps = {
    display: 'inline-flex',
    textAlign: 'left',
    alignItems: 'center',
    minWidth: 0,
    svg: {
      padding: '0 12px 0 0',
    },
  };
  const theme = useTheme();
  let icon;
  let color = '';
  switch (type) {
    case 'success':
      icon = <IconSVGSuccess css={css({ minWidth: 20 })} />;
      color = theme.palette.success.main;
      break;
    case 'warning':
      icon = <IconSVGWarning css={css({ minWidth: 20 })} />;
      color = theme.palette.warning.main;
      break;
    case 'info':
      icon = <IconSVGInfo css={css({ minWidth: 20 })} />;
      break;
    case 'error':
      icon = <IconSVGError css={css({ minWidth: 20 })} />;
      color = theme.palette.error.main;
      break;
    default:
      icon = <></>;
  }

  return (
    <Typography component={'span'} sx={styles} variant={'18_24_700'}>
      {icon}
      <Nowrap>{children}</Nowrap>
    </Typography>
  );
};
