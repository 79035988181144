/** @jsxImportSource @emotion/react */
import { ButtonBase, css as _, styled } from '@mui/material';

/**
 * @deprecated for other purposes use ui-kit IconButton
 */
export const IconButton = styled(ButtonBase)(
  ({ theme }) => _`
    min-width: 42px;
    height: 42px;
    background: none;
    border: none;
    cursor: pointer;
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    '@media(hover: hover)': {
      &:hover {
          path {
              fill: ${theme.palette.grey[700]};
          }
          opacity: .5
      }
  },
    &:active {
        opacity: .3;
    }
`
);
