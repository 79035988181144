import { ReactComponent as IconSVGTimes } from '@/images/icons/icon_times.svg';
import React, { memo, PropsWithChildren } from 'react';
import {
  StyledDialogTitle,
  StyledDialogContent,
  StyledDialogActions,
} from './styles';
import { Box, SxProps, Typography } from '@mui/material';
import { StyledDialog } from './StyledDialog';
import { IconButton } from 'ui-kit';
import { Nowrap } from '@/components';
// TODO make it not to affect on scrollbar of the app

export const Dialog = StyledDialog;

interface DialogTitleProps {
  onClose?: () => void;
  className?: string;
  noContent?: boolean;
  sx?: SxProps;
}
export const DialogTitle = memo(
  ({
    onClose,
    children,
    className,
    noContent,
    sx,
  }: PropsWithChildren<DialogTitleProps>) => {
    return (
      <StyledDialogTitle className={className} sx={sx} component={'div'}>
        {noContent ? (
          children
        ) : (
          <Typography
            variant={'18_24_700'}
            component={'h2'}
            sx={{ minWidth: 200 }}
          >
            <Nowrap>{children}</Nowrap>
          </Typography>
        )}
        {!noContent && (
          <IconButton onClick={onClose} color={'black'}>
            <Box component={IconSVGTimes} />
          </IconButton>
        )}
      </StyledDialogTitle>
    );
  }
);
DialogTitle.displayName = 'DialogTitle';
export const DialogContent = StyledDialogContent;
export const DialogActions = StyledDialogActions;
