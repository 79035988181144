import { CompanyHeader } from '@/features/company/fragments/CompanyHeader';
import React, { FC } from 'react';
import { useSessionOwnerData } from '@/store/profile/hooks';
import { useParams } from '@/routes/appRoutes';
import { UsersControlReact } from '@/features/users-control/UsersControlReact';
import { errorToast, successToast } from '@/components/Toast';
import { TKeys, useTranslate } from '@/i18n/useTranslate';
import { useCompanyPermissions } from '@/store/permissions/PermissionsKeeper';

export const CompanyUsersControlPage: FC = () => {
  const { companyId } = useParams();
  const { account: sessionOwner } = useSessionOwnerData();
  const { t } = useTranslate('companies');
  const p = useCompanyPermissions(companyId);
  return (
    <>
      <CompanyHeader />
      <UsersControlReact
        companyId={companyId}
        accountId={sessionOwner?.id}
        permissions={p.permissions}
        xModuleType={'PASSPORT'}
        onEvent={(e, id) => {
          switch (e.detail.type) {
            case 'NOTIFY_SUCCESS':
              successToast({
                text: t(e.detail.text as TKeys, { at: e.detail.data }),
              });
              break;
            case 'NOTIFY_ERROR':
              errorToast({ text: e.detail.text as TKeys });
              break;
            case 'WRONG_PERMISSIONS':
              p.update();
          }
        }}
      />
    </>
  );
};
