import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import { getErrorForDisplay } from '@/components/FormikRedux/useFormikError';
import { SelectControl, SelectControlProps } from 'ui-kit';

export const SelectControlF: FC<
  Omit<SelectControlProps, 'name' | 'value'> & {
    name: string;
  }
> = (props) => {
  return (
    <Field {...props}>
      {({ field, form, meta }: FieldProps) => {
        const e = getErrorForDisplay({
          error: meta.error,
          value: meta.value,
          touched: meta.touched,
          submitCount: form.submitCount,
        });
        return <SelectControl {...props} {...field} error={e} />;
      }}
    </Field>
  );
};
