import { AppRoutes } from '@/routes/appRoutes';
import { useFormikContext } from 'formik';
import { TKeys, useTranslate } from '@/i18n/useTranslate';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Nullable } from '@/types';
import { useEditContact, useProfileData } from '@/store/profile/hooks';
import * as yup from 'yup';
import { DateControlF } from '@/components/DateControl';
import { FormikRedux, Grid } from '@/components';
import { WhiteBox } from '@/components/WhiteBox';
import { ListItemText, Stack } from '@mui/material';
import { DateTime } from 'luxon';
import {
  BirthSex,
  Contact,
  ContactEthnicityEnum,
  ContactGenderEnum,
  ContactRaceEnum,
  ContactSexualOrientationEnum,
} from '@/api/__generated__/webApi';
import { TranslationKeys } from '@/i18n/TranslationKeys';
import {
  BIRTH_SEX_ENUM_MAP,
  ETHNICITY_ENUM_MAP,
  GENDER_ENUM_MAP,
  RACE_ENUM_MAP,
  SEXUAL_ORIENTATION_ENUM_MAP,
} from '@/features/profile/ProfileEdit/enumMaps';
import { EMPTY } from '@/constants';
import { InputControlF, SelectControlF } from '@/components/FormikRedux';
import { Button, Option } from 'ui-kit';

type OptionKeys = Extract<
  keyof Contact,
  'birthSex' | 'ethnicity' | 'gender' | 'race' | 'sexualOrientation'
>;

const styles = {
  buttonsLayout: {
    justifyContent: { xs: 'initial', sm: 'space-between', md: 'initial' },
    button: { width: { xs: '100%', sm: 'auto' } },
    mt: { xs: 40, sm: 32 },
    gap: 24,
    flexDirection: 'row',
  },
};

const getTranslations = <T extends Record<string, string>>(
  enumeration: T,
  map: Record<T[keyof T], keyof TranslationKeys['common']>,
  t: (str: TKeys<'common'> | undefined | null) => string | undefined
): Record<T[keyof T], string> => {
  // eslint-disable-next-line
  return Object.values(enumeration).reduce<any>((acc, value) => {
    // @ts-expect-error
    acc[value] = t(map[value]);
    return acc;
  }, {});
};
const useOptions = (): Record<
  OptionKeys,
  Array<{ value: string; label: string }>
> => {
  const { t } = useTranslate('common');

  return useMemo<
    Record<OptionKeys, Array<{ label: string; value: string }>>
  >(() => {
    const birthSex = getTranslations(BirthSex, BIRTH_SEX_ENUM_MAP, t);

    const ethnicity = getTranslations(
      ContactEthnicityEnum,
      ETHNICITY_ENUM_MAP,
      t
    );
    const gender = getTranslations(ContactGenderEnum, GENDER_ENUM_MAP, t);
    const race = getTranslations(ContactRaceEnum, RACE_ENUM_MAP, t);

    const sexualOrientation = getTranslations(
      ContactSexualOrientationEnum,
      SEXUAL_ORIENTATION_ENUM_MAP,
      t
    );

    return {
      birthSex: Object.entries(birthSex).map(([value, label]) => ({
        label,
        value,
      })),
      ethnicity: Object.entries(ethnicity).map(([value, label]) => ({
        label,
        value,
      })),
      gender: Object.entries(gender).map(([value, label]) => ({
        label,
        value,
      })),
      race: Object.entries(race).map(([value, label]) => ({ label, value })),
      sexualOrientation: Object.entries(sexualOrientation).map(
        ([value, label]) => ({ label, value })
      ),
    };
  }, [t]);
};

const useValidationSchema = () => {
  const { t } = useTranslate('profile');
  return useMemo(() => {
    return yup.object().shape({
      birthDate: yup
        .string()
        .nullable()
        .required(t('FIELD_IS_REQUIRED'))
        .test('is-18', t('TO_USE_THE_SERVICE_'), (date) => {
          const lDate = DateTime.fromISO(date ?? '');
          if (!lDate.isValid) {
            return true;
          }
          const age = DateTime.now().diff(lDate, 'years').years;
          return age >= 18;
        }),
      firstName: yup.string().nullable().required(t('FIELD_IS_REQUIRED')),
      lastName: yup.string().nullable().required(t('FIELD_IS_REQUIRED')),
      middleName: yup.string().nullable(),

      birthSex: yup.string().nullable().required(t('FIELD_IS_REQUIRED')),
      ethnicity: yup.string().nullable(),
      gender: yup.string().nullable(),
      sexualOrientation: yup.string().nullable(),
      race: yup.string().nullable(),
    });
  }, [t]);
};

const initialContact: Nullable<Contact> = {
  birthDate: '',
  firstName: '',
  lastName: '',
  middleName: '',
  race: '' as ContactRaceEnum,
  ethnicity: null,
  birthSex: null,
  gender: null,
  sexualOrientation: null,
};
export const ProfileEdit: FC = () => {
  const { details } = useProfileData();
  const {
    sendData,
    validationErrors,
    resetServerErrors,
    success,
    fetching,
    resetForm,
  } = useEditContact();
  const navigate = useNavigate();
  const contact = details?.contact ?? initialContact;
  const schema = useValidationSchema();
  const submitForm = useCallback(
    (d: Nullable<Contact>) => {
      sendData(d);
    },
    [sendData]
  );

  useEffect(() => {
    if (!fetching && success) {
      navigate({ pathname: AppRoutes.PROFILE, search: window.location.search });
      resetForm();
    }
  }, [fetching, navigate, resetForm, success]);

  return (
    <FormikRedux<Nullable<Contact>>
      id={'profile_edit_form'}
      initialValues={contact}
      onSubmit={submitForm}
      serverErrors={validationErrors}
      validationSchema={schema}
      validateOnChange
      enableReinitialize
      resetServerErrors={resetServerErrors}
    >
      <ProfileEditView />
    </FormikRedux>
  );
};

export const ProfileEditView: FC = () => {
  const { t } = useTranslate('profile');
  const navigate = useNavigate();
  const { isSubmitting } = useFormikContext<Nullable<Contact>>();

  const options = useOptions();
  return (
    <WhiteBox>
      <Grid
        container
        mb={{ md: 32, xs: 32 }}
        columnSpacing={36}
        rowSpacing={{ xs: 21 }}
      >
        <Grid xs={12} sm={6} md={4.5}>
          <InputControlF
            label={t('FIRST_NAME')}
            name={'firstName'}
            type="text"
            placeholder={t('ENTER_FIRST_NAME')}
            max={50}
          />
        </Grid>
        <Grid xs={12} sm={6} md={4.5}>
          <InputControlF
            label={t('MIDDLE_NAME')}
            name={'middleName'}
            placeholder={t('ENTER_MIDDLE_NAME')}
            optional
            max={50}
          />
        </Grid>
        <Grid xs={12} sm={6} md={4.5}>
          <InputControlF
            label={t('LAST_NAME')}
            name={'lastName'}
            max={50}
            placeholder={t('ENTER_LAST_NAME')}
          />
        </Grid>
        <Grid xs={12} sm={6} md={4.5}>
          <DateControlF
            label={t('DATE_OF_BIRTH')}
            name={'birthDate'}
            disableFutureDates
          />
        </Grid>
      </Grid>
      <Grid container mb={20} columnSpacing={36} rowSpacing={{ xs: 21 }}>
        <Grid xs={12} sm={6} md={4.5}>
          <SelectControlF
            label={t('BIRTH_SEX')}
            name={'birthSex'}
            placeholder={EMPTY}
            renderValue={(v: unknown) =>
              v
                ? t(BIRTH_SEX_ENUM_MAP[v as keyof typeof BIRTH_SEX_ENUM_MAP])
                : EMPTY
            }
          >
            {options.birthSex.map(({ value, label }, key) => {
              return (
                <Option value={value} key={key}>
                  <ListItemText primary={label} />
                </Option>
              );
            })}
          </SelectControlF>
        </Grid>
        <Grid xs={12} sm={6} md={4.5}>
          <SelectControlF
            label={t('GENDER')}
            placeholder={EMPTY}
            name={'gender'}
            optional
            renderValue={(v: unknown) =>
              v ? t(GENDER_ENUM_MAP[v as keyof typeof GENDER_ENUM_MAP]) : EMPTY
            }
          >
            {options.gender.map(({ value, label }, key) => {
              return (
                <Option value={value} key={key}>
                  <ListItemText primary={label} />
                </Option>
              );
            })}
          </SelectControlF>
        </Grid>
        <Grid xs={12} sm={6} md={4.5}>
          <SelectControlF
            label={t('SEXUAL_ORIENTATION')}
            placeholder={EMPTY}
            name={'sexualOrientation'}
            optional
            renderValue={(v: unknown) =>
              v
                ? t(
                    SEXUAL_ORIENTATION_ENUM_MAP[
                      v as keyof typeof SEXUAL_ORIENTATION_ENUM_MAP
                    ]
                  )
                : EMPTY
            }
          >
            {options.sexualOrientation.map(({ value, label }, key) => {
              return (
                <Option value={value} key={key}>
                  <ListItemText primary={label} />
                </Option>
              );
            })}
          </SelectControlF>
        </Grid>
        <Grid xs={12} sm={6} md={4.5}>
          <SelectControlF
            label={t('RACE')}
            placeholder={EMPTY}
            name={'race'}
            optional
            renderValue={(v: unknown) =>
              v ? t(RACE_ENUM_MAP[v as keyof typeof RACE_ENUM_MAP]) : EMPTY
            }
          >
            {options.race.map(({ value, label }, key) => {
              return (
                <Option value={value} key={key}>
                  <ListItemText primary={label} />
                </Option>
              );
            })}
          </SelectControlF>
        </Grid>
        <Grid xs={12} sm={6} md={4.5}>
          <SelectControlF
            label={t('ETHNICITY')}
            placeholder={EMPTY}
            name={'ethnicity'}
            optional
            renderValue={(v: unknown) =>
              v
                ? t(ETHNICITY_ENUM_MAP[v as keyof typeof ETHNICITY_ENUM_MAP])
                : EMPTY
            }
          >
            {options.ethnicity.map(({ value, label }, key) => {
              return (
                <Option value={value} key={key}>
                  <ListItemText primary={label} />
                </Option>
              );
            })}
          </SelectControlF>
        </Grid>
      </Grid>
      <Stack sx={styles.buttonsLayout}>
        <Button
          color={'primary'}
          variant="outlined"
          onClick={() =>
            navigate({
              pathname: AppRoutes.PROFILE,
              search: window.location.search,
            })
          }
        >
          {t('CANCEL')}
        </Button>
        <Button
          color="primary"
          disabled={isSubmitting}
          type={'submit'}
          form="profile_edit_form"
        >
          {t('SAVE')}
        </Button>
      </Stack>
    </WhiteBox>
  );
};
