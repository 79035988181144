/** @jsxImportSource @emotion/react */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormikRedux,
  Grid,
} from '@/components';
import { phoneNotRequiredValidator } from '@/features/authentication/common/validators';
import { TKeys, useTranslate } from '@/i18n/useTranslate';
import { FC, ReactNode, useMemo, useState } from 'react';
import * as yup from 'yup';
import { useCreateAddressForm } from '@/store/companies/hooks/useCreateAddressForm';
import { SwitchControlF } from '@/components/SwitchControl';
import { InvoiceReceivingMessage } from './EditAddressDialog';
import { CompanyAddress, StateType } from '@/api/__generated__/webApi';
import { AddressLookup } from '@/components/AddressLookup';
import { Stack, SxProps, Theme } from '@mui/material';
import { useFormikContext } from 'formik';
import { FullAddress } from '@/components/AddressLookup/AddressLookup';
import { BillTypesControls } from '@/features/company-addresses/BillTypesControls';
import { PhoneInputControlF } from '@/components/FormikRedux/PhoneInputControlF';
import { spreadSx } from '@/utils/spreadSx';
import { InputControlF } from '@/components/FormikRedux/InputControlF';
import { useBreakpoints } from 'ui-kit';

import { EMPTY } from '@/constants';

export type AddAddressesForm = Omit<
  CompanyAddress,
  'endDate' | 'id' | 'state'
> & { state: StateType | null };
const initialValues: AddAddressesForm = {
  address1: '',
  address2: '',
  state: null,
  city: '',
  postalCode: '',
  telephoneNumber: '',
  faxNumber: '',
  invoicesReceiving: false,
  insuranceBillType: false,
  patientBillType: false,
  clientBillType: false,
};
export const GrayLayout: FC<{ children: ReactNode; sx?: SxProps<Theme> }> = ({
  children,
  sx,
}) => {
  return (
    <Stack
      sx={[
        { backgroundColor: 'grey.200', padding: { xs: '24px 18px' } },
        ...spreadSx(sx),
      ]}
    >
      {children}
    </Stack>
  );
};
const useValidationSchema = () => {
  const { t } = useTranslate('auth');
  return useMemo(() => {
    return yup.object().shape({
      address1: yup.string().required(t('FIELD_IS_REQUIRED')),
      address2: yup.string(),
      state: yup.string().nullable().required(t('FIELD_IS_REQUIRED')),
      city: yup.string().required(t('FIELD_IS_REQUIRED')),
      postalCode: yup.string().required(t('FIELD_IS_REQUIRED')),
      telephoneNumber: phoneNotRequiredValidator(t),
      faxNumber: phoneNotRequiredValidator(t),
    });
  }, [t]);
};
export const AddAddressDialog = () => {
  const { t } = useTranslate('companies');
  const validationSchema = useValidationSchema();
  const { dialogOpened, closeDialog, errors, resetErrors, submit } =
    useCreateAddressForm();
  const b = useBreakpoints();
  return (
    <Dialog
      open={!!dialogOpened}
      size={b.smOnly ? 'xl' : 'xxl'}
      onClose={closeDialog}
    >
      <DialogTitle onClose={closeDialog}>{t('ADD_ADDRESS')}</DialogTitle>
      <DialogContent sx={{ paddingY: { xs: 18 } }}>
        <FormikRedux<AddAddressesForm>
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={submit}
          serverErrors={errors as any}
          resetServerErrors={resetErrors}
          id={'add_address_form'}
        >
          {({ submitCount }) => {
            return (
              <Stack gap={12}>
                <GrayLayout sx={{ gap: 24 }}>
                  <AddressLookupFormField />
                  <InputControlF
                    name={'address2'}
                    max={55}
                    placeholder={t('ENTER_APARTMENT_SUITE_OR_UNIT')}
                  />
                </GrayLayout>
                <GrayLayout
                  sx={{ paddingTop: { xs: 36 }, paddingBottom: { xs: 24 } }}
                >
                  <Grid
                    container
                    columnSpacing={{ xs: 24, sm: 42 }}
                    rowSpacing={{
                      xs: 42,
                      sm: 28,
                    }}
                  >
                    <Grid xs={12} sm={6} md={6}>
                      <PhoneInputControlF
                        ignoreLabelHeight
                        name={'telephoneNumber'}
                        label={t('PHONE_NUMBER')}
                        placeholder={EMPTY}
                        submitCount={submitCount}
                        optional
                      />
                    </Grid>
                    <Grid xs={12} sm={6} md={6}>
                      <PhoneInputControlF
                        ignoreLabelHeight
                        submitCount={submitCount}
                        name={'faxNumber'}
                        label={t('FAX_NUMBER')}
                        optional
                      />
                    </Grid>
                  </Grid>
                </GrayLayout>
                <GrayLayout sx={{ paddingY: { xs: 18 } }}>
                  <BillTypesControls />
                  <SwitchControlF
                    label={t('INVOICES_RECEIVING')}
                    sx={{
                      height: { xs: 28 },
                      mb: { xs: 12 },
                      mt: { xs: 24 },
                      justifyContent: 'start',
                      gap: 16,
                    }}
                    name={'invoicesReceiving'}
                  />
                  <InvoiceReceivingMessage />
                </GrayLayout>
              </Stack>
            );
          }}
        </FormikRedux>
      </DialogContent>
      <DialogActions spaceBetween>
        <Button variant="outlined" color={'secondary'} onClick={closeDialog}>
          {t('CANCEL')}
        </Button>
        <Button type={'submit'} form={'add_address_form'} color="secondary">
          {t('SAVE')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AddressLookupFormField = () => {
  const { setFieldValue, submitCount } = useFormikContext<AddAddressesForm>();
  const [error, setError] = useState<TKeys<'companies'> | undefined>(
    'FIELD_IS_REQUIRED'
  );
  const { t } = useTranslate('companies');
  const handleChange = (v: FullAddress | null) => {
    if (!v?.fullAddress) {
      setError('FIELD_IS_REQUIRED');
      void setFieldValue('address1', '');
      void setFieldValue('city', '');
      void setFieldValue('postalCode', '');
      void setFieldValue('state', '');
      void setFieldValue('latitude', '');
      void setFieldValue('longitude', '');
      void setFieldValue('googlePlaceId', '');
    } else if (!v?.city || !v?.state || !v?.postalCode) {
      setError('PLEASE_CLARIFY_THE_ADDRESS' as TKeys<'companies'>);
    } else if (
      v.streetAddress &&
      v.city &&
      v.postalCode &&
      v.state &&
      v.latitude &&
      v.longitude &&
      v.googlePlaceId
    ) {
      setError(undefined);
      void setFieldValue('address1', v.streetAddress);
      void setFieldValue('city', v.city);
      void setFieldValue('postalCode', v.postalCode);
      void setFieldValue('state', v.state);
      void setFieldValue('latitude', v.latitude);
      void setFieldValue('longitude', v.longitude);
      void setFieldValue('googlePlaceId', v.googlePlaceId);
    } else {
      console.error(
        '[submit address] Some of the required fields are missing',
        v
      );
    }
  };
  const err = submitCount && error ? error : undefined;
  return (
    <AddressLookup
      onChange={handleChange}
      error={t(err)}
      placeholder={t('ENTER_YOUR_COMPANY_ADDRESS')}
    />
  );
};
