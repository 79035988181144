import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from '@mui/material';
import { shouldNotForwardProps } from '@/utils/shouldNotForwardProps';

export const StyledDialogTitle = styled(Box)(({ theme }) => {
  return {
    fontSize: '18px',
    lineHeight: '22px',
    fontWeight: 700,
    padding: '0 12px 0 24px',
    borderBottom: `1px solid ${theme.colors.borderLight}`,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    height: 48,
    margin: 0,
    flexShrink: 0,
    justifyContent: 'space-between',
  };
});
StyledDialogTitle.defaultProps = {
  component: DialogTitle,
};

export const StyledDialogContent = styled(DialogContent)(({ theme: t }) => ({
  padding: 16,
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: 400,
  textAlign: 'center',
  [t.breakpoints.up('sm')]: {
    padding: '36px 24px',
  },
}));

export const StyledDialogActions = styled(
  DialogActions,
  shouldNotForwardProps(['spaceBetween', 'noBorder', 'noStack'])
)<{ spaceBetween?: boolean; noStack?: boolean; noBorder?: boolean }>(({
  spaceBetween,
  noStack,
  noBorder,
  theme: t,
}) => {
  return {
    justifyContent:
      spaceBetween || noStack ? 'space-between!important' : 'center!important',
    padding: 16,
    gap: 21,
    margin: 0,
    button: {
      margin: '0!important',
    },
    [t.breakpoints.up('xs')]: {
      borderTop: noBorder
        ? undefined
        : `1px solid ${t.palette.secondary._14 ?? ''}`,
    },
    [t.breakpoints.only('xs')]: {
      ...(!noStack && {
        gap: 12,
        flexDirection: 'column-reverse',
        button: {
          width: '100%',
        },
      }),
    },
    [t.breakpoints.up('sm')]: {
      padding: 24,
    },
  };
});
