/** @jsxImportSource @emotion/react */
import { BoxBody, WhiteBox } from '@/components/WhiteBox';
import { Link } from '@/components/Text';
import { useTranslate } from '@/i18n/useTranslate';
import React, { FC } from 'react';
import { Breadcrumbs, BreadcrumbsText } from '@/components/Breadcrumbs';
import { AppRoutes, generatePath, useParams } from '@/routes/appRoutes';
import { useRoles, useUsers } from '@/store/companies/hooks';
import { LoadingBackdrop } from '@/components/LoadingBackdrop/LoadingBackdrop';
import { Box, Typography } from '@mui/material';
import { SearchControl, useBreakpoints } from 'ui-kit';
import { CompanyHeader } from '@/features/company/fragments/CompanyHeader';
import { useModuleName } from '../ModulePage/fragments/ModuleHeader';
import { useSearch } from '@/hooks/useSearch';
import { TabletList } from './fragments/TabletList';
import { MobileList } from './fragments/MobileList';
import { DesktopList } from './fragments/DesktopList';
import { useSessionOwnerData } from '@/store/profile/hooks';
import { ModuleType } from '@/api/__generated__/webApi';

const styles = {
  headerNdSearchLayout: {
    display: 'flex',
    gap: { xs: 36, sm: 36, lg: 46 },
    justifyContent: 'space-between',
    mb: { xs: 36, lg: 64 },
    flexDirection: { xs: 'column', lg: 'row' },
  },
  listsBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: 0,
    position: 'relative',
  },
};
export const UsersList: FC = () => {
  const { companyId, moduleId, module } = useParams();
  const { role } = useRoles();
  const { t, ready } = useTranslate('companies');
  const {
    search,
    moduleUsers,
    companyUsers,
    removeUser,
    addUser,
    loadMoreCompanyUsers,
    loadMoreModuleUsers,
    fetching,
  } = useUsers();
  const b = useBreakpoints();
  const { name, moduleType } = useModuleName();
  const { account } = useSessionOwnerData();
  const isPassport = moduleType === ModuleType.PASSPORT;

  const { handleChange } = useSearch((val, type) => {
    if (val.length > 2 || !val.length) {
      search(val, type as 'company' | 'module' | undefined);
    }
  });

  const roleUserListLabel = t('ROLE_LIST_LABEL', {
    roleName: role?.name ?? '...',
  });

  if (!ready) {
    return <>...</>;
  }

  return (
    <>
      <CompanyHeader />
      <Breadcrumbs>
        <Link
          to={{
            pathname: generatePath(AppRoutes.COMPANY_MODULES, {
              companyId,
              moduleId,
              module,
            }),
            search: window.location.search,
          }}
        >
          {name}
        </Link>
        <BreadcrumbsText>{t('USERS_LIST')}</BreadcrumbsText>
      </Breadcrumbs>
      {b.xsOnly || b.smOnly ? (
        <WhiteBox>
          <Box sx={styles.headerNdSearchLayout}>
            <Typography variant={'h2'} lineHeight={{ xs: '34px' }}>
              {t('MODULE_USER_LIST', { moduleName: name })}
            </Typography>
            <SearchControl
              placeholder={t('SEARCH_USERS')}
              onChange={handleChange}
            />
          </Box>
          <BoxBody sx={styles.listsBody}>
            <LoadingBackdrop open={fetching} />
            {b.smOnly ? (
              <TabletList
                moduleUsers={moduleUsers}
                companyUsers={companyUsers}
                removeUser={removeUser}
                addUser={addUser}
                loadMoreCompanyUsers={loadMoreCompanyUsers}
                loadMoreModuleUsers={loadMoreModuleUsers}
                roleUsersListLabel={roleUserListLabel}
                prohibitRemovalOfUser={isPassport ? account?.id : undefined}
              />
            ) : (
              <MobileList
                moduleUsers={moduleUsers}
                companyUsers={companyUsers}
                removeUser={removeUser}
                addUser={addUser}
                loadMoreCompanyUsers={loadMoreCompanyUsers}
                loadMoreModuleUsers={loadMoreModuleUsers}
                roleUsersListLabel={roleUserListLabel}
                prohibitRemovalOfUser={isPassport ? account?.id : undefined}
              />
            )}
          </BoxBody>
        </WhiteBox>
      ) : (
        <DesktopList
          onSearchChange={handleChange}
          moduleUsers={moduleUsers}
          companyUsers={companyUsers}
          removeUser={removeUser}
          addUser={addUser}
          loadMoreCompanyUsers={loadMoreCompanyUsers}
          loadMoreModuleUsers={loadMoreModuleUsers}
          roleUsersListLabel={roleUserListLabel}
          prohibitRemovalOfUser={isPassport ? account?.id : undefined}
        />
      )}
    </>
  );
};
