/** @jsxImportSource @emotion/react */
import { Button } from '@/components/Button';
import { InputControl } from '@/components/InputControl';
import { FormikProps, Formik } from 'formik';
import { useTranslate } from '@/i18n/useTranslate';
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import * as yup from 'yup';
import { passwordValidator } from '@/features/authentication/common/validators';
import { useInit } from '@/hooks/useInit';
import { useChangePasswordRequest } from '@/store/profile/apiHooks';
import { useNavigate } from 'react-router';
import { AppRoutes } from '@/routes/appRoutes';
import { RecaptchaNote } from '@/components/RecaptchaNote';
import { Stack, Typography } from '@mui/material';
import { Notification } from '@/components/Notification';
import { ButtonsLayout } from '@/features/profile/phones-and-emails/components/ButtonsLayout';
import { useBreakpoints } from 'ui-kit';
import { useTranslatedErrors } from '@/hooks/useConnectServerValidationErrors';
//
const useValidationSchema = () => {
  const { t } = useTranslate('common');
  const { t: tr } = useTranslate('password');
  return useMemo(() => {
    return yup.object().shape({
      currentPassword: yup.string().required(t('FIELD_IS_REQUIRED')),
      newPassword: passwordValidator(tr),
      confirmPassword: yup
        .string()
        .required(t('PLEASE_CONFIRM_PASSWORD'))
        .oneOf([yup.ref('newPassword')], t('PASSWORDS_DO_NOT_MATCH')),
    });
  }, [t, tr]);
};

interface PasswordFormState {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}
const initialState = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};
export const PasswordForm: FC<{ next: () => void }> = ({ next }) => {
  const { t } = useTranslate('profile');
  const schema = useValidationSchema();
  const { sendData, validationErrors, fetching, responseData, reset } =
    useChangePasswordRequest();
  const formikRef = useRef<FormikProps<PasswordFormState>>(null);
  const { xsOnly, smOnly, sm } = useBreakpoints();
  const navigate = useNavigate();
  const translatedErrors = useTranslatedErrors(validationErrors?.fieldErrors);

  const handleSubmit = useCallback(
    (values: PasswordFormState) => {
      sendData(values);
    },
    [sendData]
  );

  useInit(() => {
    reset();
  });

  // set formik fetching false if fetching state changed from true to false
  useEffect(() => {
    const f = fetching;
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      f && formikRef.current?.setSubmitting(false);
    };
  }, [fetching]);

  useEffect(() => {
    if (responseData?.resendTime) {
      next();
    }
  }, [responseData?.resendTime, next, reset]);

  // formik sync with server validation errors
  useEffect(() => {
    if (!translatedErrors) {
      return;
    }
    formikRef.current?.setErrors({
      ...formikRef.current?.errors,
      ...translatedErrors,
    });
    if (validationErrors?.error) {
      formikRef.current?.setFieldError(
        'currentPassword',
        t(validationErrors.error)
      );
    }
  }, [t, translatedErrors, validationErrors]);

  return (
    <>
      <Formik<PasswordFormState>
        innerRef={formikRef}
        initialValues={initialState}
        onSubmit={handleSubmit}
        validateOnChange={true}
        validationSchema={schema}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
          isSubmitting,
        }) => {
          return (
            <>
              <Stack sx={{ flex: '1 1 auto' }}>
                <Stack>
                  {!xsOnly && (
                    <Typography
                      mb={48}
                      variant={'16_24_500'}
                      sx={{
                        fontSize: { sm: 14 },
                        lineHeight: { sm: '20px' },
                        mb: { sm: 48 },
                      }}
                    >
                      {t(
                        'YOU_CAN_CHANGE_YOUR_PASSWORD_TO_DO_THIS_ENTER_YOUR_CURRENT_PASSWORD_AND_YOUR_NEW_PASSWORD'
                      )}
                    </Typography>
                  )}
                  <Stack
                    sx={{
                      maxWidth: { xs: 'auto', sm: 480, lg: 480 },
                      gap: { xs: 48 },
                    }}
                    component={'form'}
                    id={'password_change_form'}
                    onSubmit={handleSubmit}
                  >
                    <InputControl
                      label={t('CURRENT_PASSWORD')}
                      type="password"
                      placeholder={
                        xsOnly
                          ? t('YOUR_PASSWORD')
                          : t('PLEASE_ENTER_YOUR_OLD_PASSWORD')
                      }
                      name="currentPassword"
                      value={values.currentPassword}
                      error={touched.currentPassword && errors.currentPassword}
                      onChange={handleChange}
                      ignoreLabelHeight
                    />
                    <InputControl
                      label={t('NEW_PASSWORD')}
                      type="password"
                      placeholder={
                        xsOnly
                          ? t('YOUR_PASSWORD')
                          : t('PLEASE_ENTER_YOUR_NEW_PASSWORD')
                      }
                      name="newPassword"
                      value={values.newPassword}
                      error={touched.newPassword && errors.newPassword}
                      onChange={handleChange}
                      ignoreLabelHeight
                    />
                    <InputControl
                      label={t('NEW_PASSWORD_CONFIRMATION')}
                      type="password"
                      placeholder={
                        xsOnly
                          ? t('YOUR_PASSWORD')
                          : t('PLEASE_RETYPE_YOUR_NEW_PASSWORD')
                      }
                      name="confirmPassword"
                      value={values.confirmPassword}
                      error={touched.confirmPassword && errors.confirmPassword}
                      onChange={handleChange}
                      ignoreLabelHeight
                    />
                  </Stack>
                  <Notification
                    type={'warning'}
                    sx={{
                      mt: { xs: 36, sm: 48, lg: 48 },
                      maxWidth: { xs: 'auto', sm: 480, lg: 480 },
                    }}
                  >
                    {t('PLEASE_CREATE_A_STRONG_PASSWORD_')}
                  </Notification>
                </Stack>
                <ButtonsLayout>
                  {smOnly && (
                    <Button
                      color={'primary'}
                      variant={'outlined'}
                      onClick={() => navigate(AppRoutes.PROFILE)}
                    >
                      {t('CANCEL')}
                    </Button>
                  )}
                  <Button
                    type="submit"
                    color="primary"
                    disabled={isSubmitting}
                    form="password_change_form"
                    fullWidth={xsOnly}
                  >
                    {t('SEND')}
                  </Button>
                </ButtonsLayout>
                <RecaptchaNote
                  mt={{ xs: 27, sm: 36, lg: 24 }}
                  mb={{ xs: 0, sm: 20 }}
                />
              </Stack>
              {sm && (
                <Typography variant={'14_18_500'}>
                  {t('AFTER_CHANGING_THE_PASSWORD_THE_ACCOUNT_WILL_BE_')}
                </Typography>
              )}
            </>
          );
        }}
      </Formik>
    </>
  );
};
