import { Box } from '@mui/material';
import { IconButton } from './IconButton';
import { ReactComponent as IconSVGHamburger } from '@/images/icons/icon_hamburger.svg';
import { ReactComponent as IconSVGTimesMenu } from '@/images/icons/icon_times_menu.svg';

export const HamburgerButton = ({
  toggle,
  open,
}: {
  toggle: () => void;
  open: boolean;
}) => {
  return (
    <IconButton
      sx={(theme) => ({ color: theme.palette.grey[800] })}
      onClick={toggle}
    >
      {open ? (
        <Box
          component={IconSVGTimesMenu}
          sx={{ path: { fill: (t) => t.palette.grey[800] } }}
        />
      ) : (
        <Box
          component={IconSVGHamburger}
          sx={(t) => ({ line: { stroke: t.palette.grey[800] } })}
        />
      )}
    </IconButton>
  );
};
