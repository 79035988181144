import { getErrors } from '@/store/common/error-handlers';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { selectProfileData } from '@/store/phones/sagas';
import {
  all,
  call,
  put,
  select,
  StrictEffect,
  take,
  takeLeading,
} from 'redux-saga/effects';
import { RootState } from '@/store/store';
import { accountApi } from '@/api';
import { npiActions as actions } from './slice';
import { AccountDetails } from '@/types';
import { ValidationErrorType } from '@/types/ValidationError';
const {
  setCommonError,
  setErrors,
  setIsFetching,
  setNpiInfo,
  setRequestSuccess,
} = actions;
export function* npiSaga() {
  yield all([
    takeLeading(actions.requestAccountNpiData, requestAccountNpiData),
    takeLeading(actions.requestNPIInfo, requestNPIInfo),
    takeLeading(actions.requestConfirm, requestConfirm),
  ]);
}
function* waitFor(selector: any): any {
  if (yield select(selector)) return;

  while (true) {
    yield take('*');
    if (yield select(selector)) return;
  }
}

function* requestAccountNpiData(): Generator<StrictEffect, void, any> {
  yield put(setIsFetching(true));

  try {
    yield call(waitFor, selectProfileData);
    const account: AccountDetails = yield select(selectProfileData);
    if (account.npi) {
      const npi: Awaited<ReturnType<typeof accountApi.getAccountNpi>> =
        yield call(accountApi.getAccountNpi);
      yield put(setNpiInfo({ confirmed: true, npi, step: 'processed' }));
    } else {
      throw { response: { data: { error: 'NPI_NOT_FOUND' } } } as AxiosError;
    }
  } catch (e) {
    if (e.response?.data?.error === 'NPI_NOT_FOUND') {
      yield call(checkIfNPIIsInProcess);
      return;
    }
    yield put(setCommonError(ValidationErrorType.UNKNOWN_ERROR));
  } finally {
    yield put(setIsFetching(false));
  }
}
function* checkIfNPIIsInProcess() {
  try {
    yield call(accountApi.checkNPIIsBeingProcessed);
    yield put(setNpiInfo({ confirmed: false, npi: null, step: 'init' }));
  } catch (e) {
    if (e.response?.status === 409) {
      yield put(
        setNpiInfo({ confirmed: false, npi: null, step: 'processing' })
      );
    } else {
      yield put(setCommonError(ValidationErrorType.UNKNOWN_ERROR));
    }
  } finally {
    yield put(setIsFetching(false));
  }
}
function* requestNPIInfo({
  payload,
}: PayloadAction<string>): Generator<StrictEffect, void, any> {
  yield put(setIsFetching(true));
  try {
    const npi = yield call(accountApi.requestNPIInfo, payload);
    yield put(setNpiInfo({ confirmed: false, npi, step: 'registration' }));
  } catch (e) {
    const errors = getErrors(e);
    if (errors.common === ValidationErrorType.NPI_TYPE_INCORRECT) {
      errors.npi = ValidationErrorType.NPI_TYPE_INCORRECT_INDIVIDUAL;
      delete errors.common;
    }
    yield put(setErrors(errors));
  } finally {
    yield put(setIsFetching(false));
  }
}

function* requestConfirm(): Generator<StrictEffect, void, any> {
  const { npiNumber } = yield select((rootState: RootState) => rootState.npi);
  yield put(setIsFetching(true));
  try {
    yield call(accountApi.confirmNPIInfo, npiNumber);
    yield put(setNpiInfo({ confirmed: false, npi: null, step: 'processing' }));
    yield put(setRequestSuccess(true));
  } catch (e) {
    const errors = getErrors(e);
    yield put(setErrors(errors));
  } finally {
    yield put(setIsFetching(false));
  }
}
