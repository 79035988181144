/** @jsxImportSource @emotion/react */
import { useTranslate } from '@/i18n/useTranslate';
import { FC, useState } from 'react';
import { BoxHeader, WhiteBox } from '@/components/WhiteBox/WhiteBox';
import { Grid, GridContainer, Link, Nothing } from '@/components';
import { ReactComponent as IconSVGPencil } from '@/images/icons/icon_pencil.svg';
import { Box, css as _, Stack } from '@mui/material';
import { Button, FormattedPhoneNumber, useBreakpoints } from 'ui-kit';
import { ReactComponent as IconSVGPlus } from '@/images/icons/icon_plus.svg';
import { useCreateAddressForm } from '@/store/companies/hooks/useCreateAddressForm';
import { AddAddressDialog } from './AddAddressDialog';
import { useEditAddressForm } from '@/store/companies/hooks/useEditAddressForm';
import { EditAddressDialog } from './EditAddressDialog';
import { Dl } from '@/components/Dl';
import { useCompanyAddresses } from '@/store/companies/hooks';
import { PillButton, PillsContainer } from '@/components/Pills';
import { AlertSign } from '@/components/AlertSign';
import {
  PermissionsKeeper,
  useCompanyPermissionsValidator,
} from '@/store/permissions/PermissionsKeeper';
import { CompanyPermissionName } from '@/types';
import { usePagination } from '@/components/Pagination/usePagination';
import { Next, Pagination, Prev } from '@/components/Pagination';
import { CompanyAddress } from '@/api/__generated__/webApi';
import { formatDate } from '@/components/DateFormat';
import { EMPTY } from '@/constants';
import { getLatLongLink } from '@/features/common/utils/getLatLongLink';
import { ReactComponent as IconItemsDivided } from '@/images/icons/icon_items_divided.svg';
export const NPIPracticeAddresses: FC = () => {
  const { t } = useTranslate('companies');
  const { expired: notPaginatedExpired, actual: notPaginatedActual } =
    useCompanyAddresses();
  const { openDialog } = useCreateAddressForm();
  const { openDialog: openEditDialog } = useEditAddressForm();
  const [selectedAddress, setSelectedAddress] = useState<
    CompanyAddress | undefined
  >();
  const { xs, sm, md, lg, xsOnly, smOnly, mdOnly, lgOnly } = useBreakpoints();
  const [shownAddresses, setShownAddresses] = useState<'actual' | 'expired'>(
    'actual'
  );
  const has = useCompanyPermissionsValidator(true);
  const showAddAddress =
    has(CompanyPermissionName.CompanyManageAddresses) &&
    shownAddresses === 'actual';
  const handleEditAddress = (address: CompanyAddress) => () => {
    setSelectedAddress(address);
    openEditDialog();
  };

  const {
    items: actual,
    next,
    prev,
    reset,
    prevDisabled,
    nextDisabled,
    show: showPagination,
  } = usePagination({ perPage: 12, items: notPaginatedActual });
  const {
    items: expired,
    next: nextExpired,
    prev: prevExpired,
    reset: resetExpired,
    prevDisabled: prevDisabledExpired,
    nextDisabled: nextDisabledExpired,
    show: showPaginationExpired,
  } = usePagination({ perPage: 12, items: notPaginatedExpired });
  const resetPagination = () => {
    reset();
    resetExpired();
  };

  const boolToYesNo = (bool: boolean | null | undefined) => {
    if (bool == null) {
      return t('NO');
    }
    return bool ? t('YES') : t('NO');
  };

  return (
    <WhiteBox>
      <Stack
        sx={{
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
        }}
      >
        <BoxHeader sx={{ mb: { xs: 36, sm: 24 } }}>
          {xsOnly ? (
            t('PRACTICE_ADDRESSES')
          ) : (
            <>
              {shownAddresses === 'actual'
                ? t('ACTUAL_ADDRESSES')
                : t('EXPIRED_ADDRESSES')}
            </>
          )}
        </BoxHeader>
        <Stack
          sx={{
            flexDirection: { xs: 'column-reverse', sm: 'row' },
            width: { xs: '100%', sm: 'initial' },
            alignItems: { xs: 'flex-start', sm: 'center' },
            gap: { xs: 36 },
            mb: 36,
            justifyContent: 'space-between',
          }}
        >
          {showAddAddress ? (
            <Link
              to={''}
              onClick={openDialog}
              sx={[
                {
                  whiteSpace: 'nowrap',
                  minWidth: 100,
                  color: (t) => `${t.palette.primary.main}!important`,
                  typography: { xs: '14_18_500' },
                  fontWeight: `${600}!important`,
                },
              ]}
            >
              <IconSVGPlus
                css={(t) => _`path {fill: ${t.palette.primary.main}}`}
              />
              {t('ADD_ADDRESS')}
            </Link>
          ) : xsOnly ? null : (
            <Box />
          )}
          <PillsContainer fullWidth={xsOnly}>
            <PillButton
              selected={shownAddresses === 'actual'}
              onClick={() => {
                setShownAddresses('actual');
                resetPagination();
              }}
              sx={{ gap: 10 }}
            >
              <span>{t('ACTUAL')}</span>
              <IconItemsDivided />
            </PillButton>
            <PillButton
              selected={shownAddresses === 'expired'}
              onClick={() => {
                setShownAddresses('expired');
                resetPagination();
              }}
              sx={{ gap: 10 }}
            >
              <span>{t('EXPIRED')}</span>
              <IconItemsDivided />
            </PillButton>
          </PillsContainer>
        </Stack>
      </Stack>
      {shownAddresses === 'actual' &&
        (actual?.length ? (
          <>
            <GridContainer rowSpacing={{ xs: 30, sm: 48 }}>
              {actual?.map((address, i) => {
                return (
                  <Grid xs={12} md={6} lg={4} xl={3} key={i}>
                    <Box mb={20}>
                      <Dl>
                        <dt>{t('ADDRESS_1')}</dt>
                        <dd>{address?.address1 || EMPTY}</dd>
                      </Dl>
                      <Dl>
                        <dt>{t('ADDRESS_2')}</dt>
                        <dd>{address?.address2 || EMPTY}</dd>
                      </Dl>
                      <Dl>
                        <dt>{t('CITY')}</dt>
                        <dd>{address?.city || EMPTY}</dd>
                      </Dl>
                      <Dl>
                        <dt>{t('STATE')}</dt>
                        <dd>{address?.state || EMPTY}</dd>
                      </Dl>
                      <Dl>
                        <dt>{t('POSTAL_CODE')}</dt>
                        <dd>{address?.postalCode || EMPTY}</dd>
                      </Dl>
                      <Dl>
                        <dt>{t('PHONE_NUMBER')}</dt>
                        <dd>
                          <FormattedPhoneNumber>
                            {address?.telephoneNumber || EMPTY}
                          </FormattedPhoneNumber>
                        </dd>
                      </Dl>
                      <Dl>
                        <dt>{t('FAX_NUMBER')}</dt>
                        <dd>
                          <FormattedPhoneNumber>
                            {address?.faxNumber || EMPTY}
                          </FormattedPhoneNumber>
                        </dd>
                      </Dl>
                      <Dl>
                        <dt>{t('INSURANCE_BILL_TYPE')}</dt>
                        <dd>{boolToYesNo(address?.insuranceBillType)}</dd>
                      </Dl>
                      <Dl>
                        <dt>{t('PATIENT_BILL_TYPE')}</dt>
                        <dd>{boolToYesNo(address?.patientBillType)}</dd>
                      </Dl>
                      <Dl>
                        <dt>{t('CLIENT_BILL_TYPE')}</dt>
                        <dd>{boolToYesNo(address?.clientBillType)}</dd>
                      </Dl>
                      <Dl>
                        <dt>{t('LOCATION')}</dt>
                        <Box component={'dd'}>{getLatLongLink(address)}</Box>
                      </Dl>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: { xs: 'start' },
                        alignItems: 'center',
                        gap: { xs: 34, sm: 32 },
                      }}
                    >
                      <PermissionsKeeper
                        show={(has) =>
                          has(CompanyPermissionName.CompanyManageAddresses)
                        }
                        replaceWith={<div />}
                      >
                        <Button
                          variant={'text'}
                          color={'secondary'}
                          onClick={handleEditAddress(address)}
                          strokeIcon
                          startIcon={<IconSVGPencil />}
                          size={'sm'}
                          sx={{
                            paddingX: 10,
                            marginX: -10,
                            minWidth: 'auto',
                            fontSize: '14px',
                            '.MuiButton-icon': {
                              width: 'auto',
                            },
                          }}
                        >
                          {t('EDIT')}
                        </Button>
                      </PermissionsKeeper>
                      {address.invoicesReceiving ? (
                        <AlertSign
                          title={t('INVOICE_RECEIVING_MAIL_NOTIFICATION_TEXT')}
                          placement={'bottom'}
                          css={(t) =>
                            _`svg path {fill: ${t.palette.secondary.main}}`
                          }
                        />
                      ) : (
                        <div />
                      )}
                    </Box>
                  </Grid>
                );
              })}
            </GridContainer>
            <Box width={'100%'} display={'flex'} mt={24}>
              <Pagination
                show={showPagination}
                css={_`justify-content: space-between; flex: 1`}
              >
                <Prev onClick={prev} disabled={prevDisabled} />
                <Next onClick={next} disabled={nextDisabled} />
              </Pagination>
            </Box>
          </>
        ) : (
          <Nothing>{t('EMPTY')}</Nothing>
        ))}
      {shownAddresses === 'expired' &&
        (expired?.length ? (
          <>
            <GridContainer rowSpacing={30}>
              {expired?.map((address, i) => {
                return (
                  <Grid xs={12} sm={6} lg={4} xl={3} key={i}>
                    <Dl>
                      <dt>{t('ADDRESS_1')}</dt>
                      <dd>{address?.address1 || EMPTY}</dd>
                    </Dl>
                    <Dl>
                      <dt>{t('ADDRESS_2')}</dt>
                      <dd>{address?.address2 || EMPTY}</dd>
                    </Dl>
                    <Dl>
                      <dt>{t('CITY')}</dt>
                      <dd>{address?.city || EMPTY}</dd>
                    </Dl>
                    <Dl>
                      <dt>{t('STATE')}</dt>
                      <dd>{address?.state || EMPTY}</dd>
                    </Dl>
                    <Dl>
                      <dt>{t('POSTAL_CODE')}</dt>
                      <dd>{address?.postalCode || EMPTY}</dd>
                    </Dl>
                    <Dl>
                      <dt>{t('PHONE_NUMBER')}</dt>
                      <dd>
                        <FormattedPhoneNumber>
                          {address?.telephoneNumber || EMPTY}
                        </FormattedPhoneNumber>
                      </dd>
                    </Dl>
                    <Dl>
                      <dt>{t('FAX_NUMBER')}</dt>
                      <dd>
                        <FormattedPhoneNumber>
                          {address?.faxNumber || EMPTY}
                        </FormattedPhoneNumber>
                      </dd>
                    </Dl>
                    <Dl>
                      <dt>{t('INSURANCE_BILL_TYPE')}</dt>
                      <dd>{boolToYesNo(address?.insuranceBillType)}</dd>
                    </Dl>
                    <Dl>
                      <dt>{t('PATIENT_BILL_TYPE')}</dt>
                      <dd>{boolToYesNo(address?.patientBillType)}</dd>
                    </Dl>
                    <Dl>
                      <dt>{t('CLIENT_BILL_TYPE')}</dt>
                      <dd>{boolToYesNo(address?.clientBillType)}</dd>
                    </Dl>
                    <Dl>
                      <dt>{t('END_DATE')}</dt>
                      <dd>{formatDate(address.endDate)}</dd>
                    </Dl>
                    <Dl>
                      <dt>{t('LOCATION')}</dt>
                      <Box component={'dt'} color={'secondary'}>
                        {address?.latitude && address.longitude ? (
                          <Link
                            target={'_blank'}
                            to={`https://www.google.com/maps/place/?q=place_id:${
                              address.googlePlaceId ?? ''
                            }`}
                          >{`${address.latitude}/${address.longitude}`}</Link>
                        ) : (
                          EMPTY
                        )}
                      </Box>
                    </Dl>
                  </Grid>
                );
              })}
            </GridContainer>
            <Box width={'100%'} display={'flex'} mt={24}>
              <Pagination
                show={showPaginationExpired}
                css={_`justify-content: space-between; flex: 1`}
              >
                <Prev onClick={prevExpired} disabled={prevDisabledExpired} />
                <Next onClick={nextExpired} disabled={nextDisabledExpired} />
              </Pagination>
            </Box>
          </>
        ) : (
          <Nothing>{t('EMPTY')}</Nothing>
        ))}
      <AddAddressDialog />
      <EditAddressDialog selectedAddress={selectedAddress} />
    </WhiteBox>
  );
};
