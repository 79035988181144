import { profileActions } from './slice';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { useSearchParams } from 'react-router-dom';
import { NotificationType, notifyActions } from '../notifications/slice';
import { useTranslate } from '@/i18n/useTranslate';
import { Nullable } from '@/types';
import { getName } from '@/utils/getName';
import { Contact } from '@/api/__generated__/webApi';
const dummy = {};
export const useProfileData = (active = false) => {
  const state = useProfileState();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!state.accountDetails) {
      dispatch(profileActions.requestAccountDetails());
    }
  }, [active, dispatch, state.accountDetails]);
  return {
    details: state?.accountDetails,
  };
};

export const useSessionOwnerData = () => {
  const data = useProfileData(false);
  return {
    account: data.details,
  };
};

export const profileStateSelector = (state: RootState) => state.profile;
export const useProfileState = () => useSelector(profileStateSelector);
export const useAccountAddress = () => {
  const state = useProfileState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!state.addresses) {
      dispatch(profileActions.requestAddressess());
    }
  }, [dispatch, state.addresses]);

  const callbacks = useMemo(() => {
    return {
      refetch: () => {
        dispatch(profileActions.requestAddressess());
      },
    };
  }, [dispatch]);
  return {
    addresses: state.addresses,
    fetching: state.fetching?.common,
    ...callbacks,
  };
};

export const useAccountInvitationToModule = () => {
  const dispatch = useDispatch();
  const state = useSelector((s: RootState) => s.profile);
  const [params, setParams] = useSearchParams();
  const { t, ready } = useTranslate('profile');

  useEffect(() => {
    if (state.notification?.type === 'INVITE_SUCCESSFUL' && ready) {
      dispatch(
        notifyActions.setNotification([
          {
            id: '',
            text: t('YOU_ACCEPTED_INVITATION_TO_', {
              company: state.notification.params?.companyName,
              name: getName(state.notification.params),
              phone: state.notification.params?.phone,
            }),
            type: NotificationType.Success,
          },
        ])
      );
      dispatch(profileActions.setNotification());
    }
  }, [dispatch, ready, state.notification, t]);

  return useCallback(() => {
    if (params.has('code')) {
      const inviteCode = params.get('code');
      setParams('');
      dispatch(profileActions.requestConfirmInvitation(inviteCode as string));
    } else {
      console.error('[useAccountInvitationToModule] Invitation code not found');
    }
  }, [dispatch, params, setParams]);
};

export const useAvatarUpload = () => {
  const { forms, accountDetails } = useProfileState();
  const dispatch = useDispatch();
  const callbacks = useMemo(() => {
    return {
      upload: (file: File) => {
        const formData = new FormData();
        formData.append('file', file);
        dispatch(profileActions.requestUploadAvatar({ data: formData }));
      },
      openDialog: () => {
        dispatch(
          profileActions.setForm({
            forms: { uploadAvatarFlow: { state: 'active' } },
            merge: true,
          })
        );
      },
      closeDialog: () => {
        dispatch(
          profileActions.setForm({
            forms: { uploadAvatarFlow: { state: 'done' } },
            merge: true,
          })
        );
      },
    };
  }, [dispatch]);
  return {
    avatarUrl: accountDetails?.avatarUrl,
    dialogOpened: forms.uploadAvatarFlow?.state === 'active',
    ...callbacks,
  };
};

export const useEditContact = () => {
  const dispatch = useDispatch();
  const {
    forms: { editProfile },
  } = useProfileState();
  const sendData = (contact: Nullable<Contact>) => {
    dispatch(profileActions.requestSaveAccountDetails({ contact }));
  };

  const resetForm = useCallback(() => {
    dispatch(
      profileActions.setForm({
        forms: { editProfile: undefined },
        merge: false,
      })
    );
  }, [dispatch]);

  const resetServerErrors = () => {
    dispatch(profileActions.resetErrors());
  };

  return {
    sendData,
    fetching: editProfile?.state === 'sending',
    success: editProfile?.state === 'done',
    validationErrors: editProfile?.errors ?? dummy,
    responseData: undefined,
    resetServerErrors,
    resetForm,
  };
};
