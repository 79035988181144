import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { ReactComponent as IconCheckMarkDisabled } from '@/images/icons/icon_checkmark_round.svg';
export const CompanyListItem: FC<{
  label: string;
  onClick: () => void;
}> = ({ label, onClick }) => {
  return (
    <Stack
      onClick={onClick}
      direction={'row'}
      sx={() => ({
        gap: 12,
        minHeight: 58,
        paddingLeft: 24,
        paddingRight: 12,
        paddingY: 6,
        boxSizing: 'border-box',
        cursor: 'pointer',
      })}
    >
      <Stack
        justifyContent={'center'}
        sx={(t) => ({ 'svg path': { stroke: t.palette.secondary.main } })}
      >
        <IconCheckMarkDisabled width={24} height={24} />
      </Stack>
      <Stack justifyContent={'center'}>
        <Typography variant={'14_18_500'}>{label}</Typography>
      </Stack>
    </Stack>
  );
};
