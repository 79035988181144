import { useSelectedCompanyDetails } from '@/store/companies/hooks';
import { BoxHeader, Grid, GridContainer, WhiteBox } from '@/components';
import { Stack } from '@mui/material';
import { SubHeader } from '@/features/profile/NPISearchForms/SubHeader';
import { Dl } from '@/components/Dl';
import { EMPTY } from '@/constants';
import { useMappedData } from '@/features/company-npi/useMappedData';

export const CompanyNpi = () => {
  const { details } = useSelectedCompanyDetails();
  const boxSections = useMappedData(details);
  return (
    <Stack gap={{ xs: 16, sm: 24, lg: 36 }}>
      {boxSections.map((section) => {
        return (
          <WhiteBox key={section.header}>
            <BoxHeader sx={{ mb: { xs: 24 } }}>{section.header}</BoxHeader>
            <Stack gap={{ xs: 36, sm: 48 }}>
              {section.items.map((row, i) => {
                return (
                  <Stack key={row.header ?? i}>
                    {row.header && <SubHeader>{row.header}</SubHeader>}
                    <GridContainer rowSpacing={{ xs: 36 }}>
                      {row.items.map((c) => {
                        return (
                          <Grid
                            xs={12 / section.columns.xs}
                            sm={12 / section.columns.sm}
                            md={12 / section.columns.md}
                            lg={12 / section.columns.lg}
                            key={c.header}
                          >
                            {c.header && <SubHeader>{c.header}</SubHeader>}
                            {c.items.map((item) => {
                              return (
                                <Dl key={item.label}>
                                  <dt>{item.label}</dt>
                                  <dd>{item.value || EMPTY}</dd>
                                </Dl>
                              );
                            })}
                          </Grid>
                        );
                      })}
                    </GridContainer>
                  </Stack>
                );
              })}
            </Stack>
          </WhiteBox>
        );
      })}
    </Stack>
  );
};
