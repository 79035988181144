import {
  all,
  call,
  delay,
  fork,
  put,
  select,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import { tryCatchSaga } from '@/store/sagas';
import { activationApi, authCodeApi } from '@/api';
import {
  Forms,
  Space,
  spacesToFormsMap,
} from '@/features/authentication/common/types';
import {
  authActions,
  authActions as actions,
  AuthState,
  Fetching,
} from './slice';
import { baseErrorHandler, isCommonNetworkError } from '@/store/error-handlers';
import { browserHistory, RootState } from '@/store/store';
import {
  logout,
  sendCheckAccountRequest,
  sendCodeRequest,
  sendLoginRequest,
  sendNewPasswordRequest,
  sendRegistrationRequest,
} from './request-handlers';
import { RegistrationFormState } from '@/features/authentication/Registration/fragments/FormFragment';
import { SagaPayload, SagaReturn } from '@/store/common/types';
import { globalStateResetAction } from '@/store/common/actions';
import { getError, getErrors } from '@/store/common/error-handlers';
import { companiesActions } from '@/store/companies/slice';
import { getRecaptchaToken } from '@/hooks/useRecaptcha';
import { AuthCodeType } from '@/types';
import { RecoveryCheckAccountFormState } from '@/features/authentication/Recovery/fragments/CheckAccountFragment';
import { AppRoutes } from '@/routes/appRoutes';
import { Statuses } from '@/api/client/types';
import { ValidationErrorType } from '@/types/ValidationError';
import { showError500OrUnknownToast } from '@/store/common/showError500OrUnknownToast';
import { LoginFormState } from '@/features/authentication/Login/fragments/LoginFragment';

const tryCatchSagaOptions = {
  withProgress: true,
  updateProgressAction: actions.setIsFetchingDeprecated,
  handleError: baseErrorHandler,
};

export function* authSaga() {
  yield all([
    takeLatest(actions.register, tryCatchSaga(register, tryCatchSagaOptions)),
    takeLatest(actions.sendCode, tryCatchSaga(sendCode, tryCatchSagaOptions)),
    takeLatest(actions.login, tryCatchSaga(login, tryCatchSagaOptions)),
    takeLeading(actions.requestLoginWithMfa, requestLoginWithMfa),
    takeLatest(actions.logout, tryCatchSaga(doLogout, tryCatchSagaOptions)),
    takeLatest(actions.requestNewPinCode, requestNewPinCode),
    takeLatest(actions.recoveryCheckAccount, recoveryCheckAccount),
    takeLatest(
      actions.recoverySetNewPassword,
      tryCatchSaga(recoverySetNewPassword, tryCatchSagaOptions)
    ),
    takeLeading(actions.requestInviteExists, requestInviteExists),
    takeLeading(
      actions.requestActivationSetNewPassword,
      requestActivationSetNewPassword
    ),
  ]);
}
type Action<T extends keyof typeof actions> = SagaPayload<(typeof actions)[T]>;

function* requestInviteExists({
  payload,
}: Action<'requestInviteExists'>): SagaReturn {
  try {
    yield put(
      actions.setIsFetching({ fetching: true, type: Fetching.CheckInvite })
    );
    const user: Awaited<
      ReturnType<typeof activationApi.checkAccountInviteExists>
    > = yield call(activationApi.checkAccountInviteExists, payload.inviteCode);
    yield put(actions.setUserToInvite(user));
    yield put(actions.goToStep('index'));
  } catch (e) {
    const error = getError(e);
    if (error === ValidationErrorType.ACCOUNT_INVITE_AUTHORIZED) {
      browserHistory.push({
        pathname: AppRoutes.AUTH,
        search: window.location.search,
      });
      return;
    }
    yield put(actions.goToStep('error'));
    yield put(actions.setValidationErrors({ error }));
  } finally {
    yield put(
      actions.setIsFetching({ fetching: false, type: Fetching.CheckInvite })
    );
  }
}

function* requestActivationSetNewPassword({
  payload,
}: Action<'requestActivationSetNewPassword'>): SagaReturn {
  try {
    const existingUserData = yield select(
      (state: RootState) => state.auth.userToInvite
    );
    yield put(companiesActions.setIsFetching(true));
    const recaptcha: Awaited<ReturnType<typeof getRecaptchaToken>> = yield call(
      getRecaptchaToken
    );

    if (!existingUserData.phone) {
      const data: Awaited<
        ReturnType<
          typeof activationApi.sendNewPasswordForAccountActivationByEmail
        >
      > = yield call(
        activationApi.sendNewPasswordForAccountActivationByEmail,
        payload.inviteCode,
        {
          recaptcha,
          phone: payload.phone,
          password: payload.password,
        }
      );
      yield put(actions.setResendTime(data.resendTime));
      yield put(actions.goToStep('privacy-policy'));
    } else {
      yield call(
        activationApi.sendNewPasswordForAccountActivationByPhone,
        payload.inviteCode,
        {
          recaptcha,
          password: payload.password,
        }
      );
      browserHistory.push(AppRoutes.PROFILE);
    }
  } catch (e) {
    const { common, ...errors } = getErrors(e);
    yield put(
      actions.setValidationErrors({
        error: common,
        fieldErrors: Object.entries(errors).map(([field, error]) => ({
          error,
          field,
        })),
      })
    );
  } finally {
    yield put(companiesActions.setIsFetching(false));
  }
}

function* register(action: Action<'register'>): SagaReturn {
  const result: Awaited<ReturnType<typeof sendRegistrationRequest>> =
    yield call(sendRegistrationRequest, action.payload);
  yield put(actions.setResendTime(result.resendTime));
  yield put(actions.goToStep('privacy-policy'));
}
function* login(action: Action<'login'>): SagaReturn {
  const data: Awaited<ReturnType<typeof sendLoginRequest>> = yield call(
    sendLoginRequest,
    action.payload
  );
  if (data.type === 'confirmation-required') {
    yield put(actions.setResendTime(data.resendTime));
    yield put(actions.goToStep('pin-code'));
  } else if (data.type === 'simple') {
    yield put(actions.goToStep('success'));
  } else if (data.type === 'mfa-type-required') {
    yield put(actions.setMfaOptions(data.availableMfaTypes));
    yield put(actions.goToStep('mfa-type-required'));
  }
}
function* requestLoginWithMfa({
  payload,
}: Action<'requestLoginWithMfa'>): SagaReturn {
  const state = (yield select((s: RootState) => s.auth)) as AuthState;
  const loginFormData = state.forms[Forms.LoginForm];
  try {
    yield put(authActions.setIsFetching({ fetching: true }));
    if (!loginFormData) throw new Error('No login data');
    const data: Awaited<ReturnType<typeof sendLoginRequest>> = yield call(
      sendLoginRequest,
      loginFormData
    );
    if (loginFormData.mfaType === 'SMS') {
      switch (data.type) {
        case 'simple':
          yield put(actions.goToStep('success'));
          break;
        case 'confirmation-required':
          yield put(actions.setResendTime(data.resendTime));
          yield put(actions.goToStep('pin-code'));
          break;
        default:
          throw new Error('Wrong mfa type');
      }
    } else if (loginFormData.mfaType === 'TOTP') {
      if (data.type !== 'confirmation-required') {
        throw new Error('wrong type');
      }
      yield put(actions.goToStep('mfa-code'));
    } else {
      throw new Error('MFA type unknown');
    }
  } catch (e) {
    showError500OrUnknownToast(e);
  } finally {
    yield put(authActions.setIsFetching({ fetching: false }));
  }
}
function* requestNewPinCode(action: Action<'requestNewPinCode'>): SagaReturn {
  const state: AuthState = yield select((state: RootState) => state.auth);
  let result:
    | Awaited<
        ReturnType<
          // | typeof sendLoginRequest
          typeof sendRegistrationRequest | typeof sendNewPasswordRequest
        >
      >
    | undefined;
  yield put(
    actions.resetValidationError(ValidationErrorType.CODE_VALIDATION_ERROR)
  );
  try {
    yield put(actions.setIsFetching({ fetching: true }));

    const recaptcha: Awaited<ReturnType<typeof getRecaptchaToken>> = yield call(
      getRecaptchaToken
    );
    const recoveryCheckForm = state.forms[Forms.RecoveryCheckAccountForm];
    switch (action.payload) {
      case Space.Login:
        result = yield call(authCodeApi.getResendTime, {
          type: AuthCodeType.TwoFactor,
          phone: state.forms[Forms.LoginForm]?.phone ?? '',
          recaptcha,
        });
        break;
      case Space.Registration:
        result = yield call(
          sendRegistrationRequest,
          state.forms[Forms.RegistrationForm] as RegistrationFormState
        );
        break;
      case Space.Recovery:
        if (recoveryCheckForm?.selected === 'email') {
          result = yield call(authCodeApi.getResendTime, {
            type: AuthCodeType.RecoveryEmail,
            email: recoveryCheckForm.email as string,
            recaptcha,
          });
        } else {
          result = yield call(authCodeApi.getResendTime, {
            type: AuthCodeType.RecoveryPhone,
            phone: recoveryCheckForm?.phone as string,
            recaptcha,
          });
        }
        break;
      case Space.Activation:
        result = yield call(authCodeApi.getResendTime, {
          type: AuthCodeType.Activation,
          phone: state.forms[Forms.ActivationForm]?.phone ?? '',
          recaptcha,
        });
        break;
    }
    yield put(actions.setResendTime(result?.resendTime ?? 0));
  } catch (e) {
    const error = getError(e);
    yield put(actions.setValidationErrors({ error }));
    yield put(actions.goToStep('error'));
  } finally {
    yield put(actions.setIsFetching({ fetching: false }));
  }
}

function* sendCode({ payload }: Action<'sendCode'>): SagaReturn {
  const state: AuthState = yield select((state: RootState) => state.auth);
  const code = payload.code;
  const formType = spacesToFormsMap[payload.space];
  const data = state.forms[formType] ?? {};
  const mfaType = (data as LoginFormState).mfaType;
  const result: Awaited<ReturnType<typeof sendCodeRequest>> = yield call(
    sendCodeRequest,
    {
      phone: data.phone,
      email:
        formType === Forms.RecoveryCheckAccountForm
          ? (data as RecoveryCheckAccountFormState).email
          : '',
      code,
      mfaType,
      inviteCode: payload.activationCode,
    },
    payload.space
  );

  yield put(actions.setActivationSuccessInfo(result));
  yield put(actions.goToStep('success'));
}
function* recoveryCheckAccount(
  action: Action<'recoveryCheckAccount'>
): SagaReturn {
  const params = action.payload;
  yield put(actions.setIsFetching({ type: Fetching.Common, fetching: true }));
  try {
    yield call(
      sendCheckAccountRequest,
      params as
        | { phone: string; selected: 'phone' }
        | { email: string; selected: 'email' }
    );
    yield put(actions.goToStep('new-password'));
  } catch (e: unknown) {
    if (!isCommonNetworkError(e)) {
      const error = getError(e);
      console.error('[saga -> recoveryCheckAccount]  Unknown error', e);
      yield put(actions.setBaseError({ status: 400, statusText: error }));
      return;
    }
    switch (e.response?.status) {
      case Statuses.BAD_REQUEST:
        yield put(actions.setValidationErrors(e.response?.data));
        break;
      case Statuses.UNAUTHORIZED:
        yield put(actions.goToStep('account-not-found'));
        break;
      default:
        yield put(
          actions.setBaseError({
            status: e.response?.status || 400,
            statusText: e.response?.statusText ?? 'unknown',
          })
        );
    }
  } finally {
    yield put(
      actions.setIsFetching({ type: Fetching.Common, fetching: false })
    );
  }
}
function* recoverySetNewPassword(
  action: Action<'recoverySetNewPassword'>
): SagaReturn {
  const state: AuthState = yield select((state: RootState) => state.auth);
  const type = state.forms[Forms.RecoveryCheckAccountForm]?.selected as
    | 'phone'
    | 'email';
  const data: Awaited<ReturnType<typeof sendNewPasswordRequest>> = yield call(
    sendNewPasswordRequest,
    {
      password: action.payload.password as string,
      phone: state.forms[Forms.RecoveryCheckAccountForm]?.phone ?? '',
      email: state.forms[Forms.RecoveryCheckAccountForm]?.email ?? '',
      selected: type,
    }
  );
  const resendTime = data.resendTime;

  yield put(actions.setResendTime(resendTime ?? 0));
  yield put(actions.goToStep('pin-code'));
}
function* doLogout(): SagaReturn {
  yield call(logout);
  yield fork(function* () {
    yield delay(300);
    yield put(globalStateResetAction());
  });
  browserHistory.push(`${AppRoutes.AUTH}/${window.location.search}`);
}
