import { ActivationForm } from '@/features/authentication/Activation/fragments/NewPasswordFragment';
import { useLayoutEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { authActions as actions, AuthState, Fetching } from './slice';
import { useSearchParams } from 'react-router-dom';
import { Forms } from '@/features/authentication/common/types';
import { ValidationErrorType } from '@/types/ValidationError';

export const useAuthState = () =>
  useSelector<RootState, AuthState>((state) => state.auth);

export const useAccountActivation = ({
  inviteCode,
}: {
  inviteCode: string | undefined;
}) => {
  const state = useAuthState();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const error = useMemo(() => {
    return Object.keys(state.serverValidation.errors)[0] as ValidationErrorType;
  }, [state.serverValidation.errors]);
  useLayoutEffect(() => {
    if (inviteCode) {
      dispatch(actions.requestInviteExists({ inviteCode }));
    }
  }, [dispatch, inviteCode]);

  return {
    fetching: state.fetching?.[Fetching.Common],
    completeRegistration: () => {
      const { password, phone } = state.forms[Forms.ActivationForm] ?? {};
      const inviteCode = searchParams.get('code') as string;
      if (inviteCode && password && phone) {
        dispatch(actions.goToStep('pin-code'));
      } else {
        console.error(
          `One of parameters is not provided\nInvite code: ${
            searchParams.get('code') ?? ''
          }\nPassword: ${password ?? ''}\nPhone number: ${phone ?? ''}`
        );
      }
    },
    error,
  };
};

export const useActivationNewPasswordForm = () => {
  const state = useAuthState();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const callbacks = useMemo(() => {
    return {
      handleSubmit: ({ password, phone }: ActivationForm) => {
        const inviteCode = searchParams.get('code') as string;
        if (inviteCode && password && phone) {
          dispatch(
            actions.requestActivationSetNewPassword({
              password,
              phone,
              inviteCode,
            })
          );
        } else {
          console.error(
            `One of parameters is not provided\nInvite code: ${
              searchParams.get('code') ?? ''
            }\nPassword: ${password ?? ''}\nPhone number: ${phone ?? ''}`
          );
        }
      },
      resetErrors: () => {
        dispatch(actions.resetErrors());
      },
    };
  }, [dispatch, searchParams]);

  return {
    errors: state.serverValidation.fieldErrors,
    ...callbacks,
  };
};
