import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  createNormalizedState,
  deleteByIdFromNormalizedState,
  NormalizedState,
  updateNormalizedState,
} from '@/store/common/normalized';
import { globalStateResetAction } from '@/store/common/actions';
import { PartialRecord, TimestampMs, UUID } from '@/types';
import { Email } from '@/api/__generated__/webApi';
import { ValidationErrorType } from '@/types/ValidationError';
export enum ErrorTypes {
  Common = 'common',
}
export enum Fetching {
  Common = 'common',
  CreateEmail = 'createEmail',
}
export interface EmailsState {
  errors: PartialRecord<ErrorTypes | string, ValidationErrorType> | null;
  fetching: PartialRecord<Fetching, boolean> | null;
  emails: NormalizedState<Email>;
  emailsResendTime: PartialRecord<UUID, TimestampMs>;
  formState?: { email?: string; state: 'active' | 'success' | null } | null;
}

const initialState: EmailsState = {
  errors: null,
  fetching: {},
  emails: createNormalizedState([]),
  formState: null,
  emailsResendTime: {},
};

const slice = createSlice({
  name: 'emails',
  initialState,
  reducers: {
    requestAllEmails(_) {},
    requestCreateEmail(
      _,
      _action: PayloadAction<{ email: string; main: boolean }>
    ) {},
    requestResendConfirmationEmail(
      _,
      _action: PayloadAction<{ emailId: UUID }>
    ) {},
    requestDeleteEmail(_, _action: PayloadAction<{ emailId: UUID }>) {},
    requestAllowResultsDelivery(
      _,
      _action: PayloadAction<{ emailId: UUID; allow: boolean }>
    ) {},
    requestMakeAsMain(_, _action: PayloadAction<{ emailId: UUID }>) {},
    setResendEmailTime(
      state,
      { payload }: PayloadAction<{ emailId: string; time: TimestampMs }>
    ) {
      state.emailsResendTime[payload.emailId] = payload.time;
    },
    setEmails(
      state,
      { payload }: PayloadAction<{ emails: Email[]; replace?: boolean }>
    ) {
      if (payload.replace || !state.emails.allIDs.length) {
        state.emails = createNormalizedState(payload.emails);
        return;
      }
      updateNormalizedState(state.emails, payload.emails);
    },
    deleteEmails(state, { payload }: PayloadAction<{ emailIds: UUID[] }>) {
      deleteByIdFromNormalizedState(state.emails, payload.emailIds);
    },
    setErrors(
      state,
      action: PayloadAction<PartialRecord<string, ValidationErrorType>>
    ) {
      state.errors = action.payload;
    },
    resetErrors(state) {
      state.errors = null;
    },
    setIsFetching(
      state,
      { payload }: PayloadAction<{ type?: Fetching; fetching: boolean }>
    ) {
      (state.fetching = state.fetching ?? {})[payload.type ?? Fetching.Common] =
        payload.fetching;
    },
    restoreEmails(state, { payload }: PayloadAction<NormalizedState<Email>>) {
      state.emails = payload;
    },
    setFormState(
      state,
      {
        payload,
      }: PayloadAction<{ email?: string; state: null | 'active' | 'success' }>
    ) {
      state.formState = payload;
    },
    resetForm(state) {
      state.formState = null;
      state.errors = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(globalStateResetAction, () => {
      return initialState;
    });
  },
});
export const emailsStateName = slice.name;
export const emailsStateReducer = slice.reducer;
export const emailsStateActions = slice.actions;
