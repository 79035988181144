import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useGoogleLoader } from '@/components/Google/GoogleLoaderProvider';

const redDefaultMarker =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMzMiIHZpZXdCb3g9IjAgMCAxNiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik03Ljk5OTg3IDIxLjQ5OTNDOC4xMjI1NyAyMS40OTk3IDguMjM5MTMgMjEuNDQ0NyA4LjMxNzQ5IDIxLjM0OTRDMTIuNjIgMTYuMTA5NiAxNS42NjY4IDEyLjA5NDYgMTUuNjY2OCA4LjE0MTgzQzE1LjY0MDQgNi4xMDEwNSAxNC44MjQ0IDQuMTUxMjggMTMuMzkyMiAyLjcwODJDMTEuOTYgMS4yNjUxMiAxMC4wMjUyIDAuNDQyNjE4IDguMDAwMTYgMC40MTYwMTZDNS45NzUwMSAwLjQ0MjYwNiA0LjA0MDE2IDEuMjY1MTIgMi42MDgxMyAyLjcwODJDMS4xNzU5MyA0LjE1MTQ0IDAuMzU5ODk1IDYuMTAxMTggMC4zMzM0OTYgOC4xNDE4M0MwLjMzMzQ5NiAxMi4wOTQ3IDMuMzc5OTkgMTYuMTA5MiA3LjY4NDA4IDIxLjM0OTRDNy43NjIxMSAyMS40NDQ0IDcuODc4MDIgMjEuNDk5MyA4LjAwMDQgMjEuNDk5M0g3Ljk5OTg3Wk00LjI4ODg1IDguMTQxODJDNC4yODg4NSA3LjE0OTk4IDQuNjc5OCA2LjE5ODk0IDUuMzc1NzUgNS40OTc0NkM2LjA3MTY5IDQuNzk2MTYgNy4wMTU2MiA0LjQwMjE3IDcuOTk5ODcgNC40MDIxN0M4Ljk4NDEyIDQuNDAyMTcgOS45MjgwNSA0Ljc5NjE0IDEwLjYyNCA1LjQ5NzQ2QzExLjMxOTkgNi4xOTg5NCAxMS43MTA5IDcuMTQ5OTggMTEuNzEwOSA4LjE0MTgyQzExLjcxMDkgOS4xMzM2NyAxMS4zMTk5IDEwLjA4NDkgMTAuNjI0IDEwLjc4NjJDOS45MjgwNSAxMS40ODc1IDguOTg0MTIgMTEuODgxNSA3Ljk5OTg3IDExLjg4MTVDNy4wMTU5NSAxMS44ODAzIDYuMDcyNjQgMTEuNDg2IDUuMzc3MDQgMTAuNzg0OUM0LjY4MTI3IDEwLjA4MzkgNC4yOTAwMSA5LjEzMzMzIDQuMjg4ODUgOC4xNDE4MloiIGZpbGw9IiNBODFENDIiLz4KPC9zdmc+Cg==';
export const grayMarker =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjE1IDE1IDMwIDMwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgPHBhdGggZD0iTTI5Ljk5OTYgNDVDMzAuMTU5NyA0NS4wMDA0IDMwLjMxMTcgNDQuOTI4NyAzMC40MTM5IDQ0LjgwNDVDMzYuMDI1OSAzNy45Njk5IDQwIDMyLjczMjkgNDAgMjcuNTc3MkMzOS45NjU2IDI0LjkxNTMgMzguOTAxMiAyMi4zNzIxIDM3LjAzMzEgMjAuNDg5OEMzNS4xNjUgMTguNjA3NSAzMi42NDEzIDE3LjUzNDcgMzAgMTcuNUMyNy4zNTg1IDE3LjUzNDcgMjQuODM0OCAxOC42MDc1IDIyLjk2NjkgMjAuNDg5OEMyMS4wOTg4IDIyLjM3MjMgMjAuMDM0NCAyNC45MTU0IDIwIDI3LjU3NzJDMjAgMzIuNzMzIDIzLjk3MzcgMzcuOTY5MyAyOS41ODc3IDQ0LjgwNDVDMjkuNjg5NSA0NC45MjgzIDI5Ljg0MDcgNDUgMzAuMDAwMyA0NUgyOS45OTk2Wk0yNS4xNTkyIDI3LjU3NzFDMjUuMTU5MiAyNi4yODM0IDI1LjY2OTEgMjUuMDQyOSAyNi41NzY5IDI0LjEyOEMyNy40ODQ2IDIzLjIxMzIgMjguNzE1OCAyMi42OTkzIDI5Ljk5OTYgMjIuNjk5M0MzMS4yODM0IDIyLjY5OTMgMzIuNTE0NiAyMy4yMTMyIDMzLjQyMjQgMjQuMTI4QzM0LjMzMDEgMjUuMDQyOSAzNC44NDAxIDI2LjI4MzQgMzQuODQwMSAyNy41NzcxQzM0Ljg0MDEgMjguODcwOCAzNC4zMzAxIDMwLjExMTYgMzMuNDIyNCAzMS4wMjYzQzMyLjUxNDYgMzEuOTQxMSAzMS4yODM0IDMyLjQ1NDkgMjkuOTk5NiAzMi40NTQ5QzI4LjcxNjIgMzIuNDUzNSAyNy40ODU4IDMxLjkzOTIgMjYuNTc4NSAzMS4wMjQ2QzI1LjY3MSAzMC4xMTAzIDI1LjE2MDcgMjguODcwNCAyNS4xNTkyIDI3LjU3NzFaIiBmaWxsPSIjMzc0MTUxIi8+Cjwvc3ZnPgo=';
interface MapSettings extends google.maps.MapOptions {
  googlePlace?: google.maps.places.PlaceResult;
  onChange?: (placeId: string) => void;
  hideTooltip?: boolean;
  marker?: string; // base64 icon uri
}
// US country coords
const defaultCoords = { lat: 39.8097343, lng: -98.5556199 };
const defaultMapSettings: MapSettings = {
  center: defaultCoords,
  zoom: 4,
};
export const useGoogleMap = ({
  zoom = defaultMapSettings.zoom,
  center = defaultMapSettings.center,
  googlePlace,
  hideTooltip,
  marker: markerImage,
}: MapSettings = defaultMapSettings) => {
  const ref = useRef<HTMLElement | null>(null);
  const refInfoWindow = useRef<HTMLDivElement>(null);
  const loader = useGoogleLoader();
  const [state, setState] = useState<{
    map?: google.maps.Map | undefined;
    infoWindow?: google.maps.InfoWindow | undefined;
    marker?: google.maps.Marker | undefined;
  }>({});
  const [tooltip, setTooltipInfo] = useState({ header: '', info: '' });

  useEffect(() => {
    let map: google.maps.Map;
    void loader?.importLibrary('maps').then(({ Map, InfoWindow }) => {
      if (!ref.current) {
        return;
      }
      map = new Map(ref.current, {
        center: { lat: center?.lat as number, lng: center?.lng as number },
        streetViewControl: false,
        mapTypeControl: false,
        zoom,
      });
      map.addListener('click', async (e: google.maps.MapMouseEvent) => {
        if (e.latLng) {
          try {
            // todo
          } catch {}
        }
      });
      const marker = new google.maps.Marker({
        map,
        icon: markerImage ?? redDefaultMarker,
        anchorPoint: new google.maps.Point(0, -29),
      });
      marker.setVisible(false);

      let infoWindow: google.maps.InfoWindow | undefined;
      if (refInfoWindow.current) {
        infoWindow = new InfoWindow();
        infoWindow.setContent(refInfoWindow.current);
      }
      marker.addListener('click', () => {
        infoWindow?.open(map, marker);
        if (refInfoWindow.current)
          refInfoWindow.current.style.display = 'block';
      });

      setState({ map, infoWindow, marker });
    });
    return () => {
      setState({});
      if (map) {
        map.unbindAll();
        google.maps.event.clearInstanceListeners(map);
      }
    };
  }, [center?.lat, center?.lng, loader, markerImage, zoom]);
  const setMapPlace = useCallback(
    (place: google.maps.places.PlaceResult) => {
      state.infoWindow?.close();
      state.marker?.setVisible(false);

      if (!place.geometry?.location) {
        console.error("No details available for input: '" + place.name + "'");
        return;
      }
      if (place.geometry.viewport) {
        state.map?.fitBounds(place.geometry.viewport);
      } else {
        state.map?.setCenter(place.geometry.location);
        state.map?.setZoom(17);
      }
      state.marker?.setPosition(place.geometry.location);
      state.marker?.setVisible(true);
      if (!hideTooltip) {
        state.infoWindow?.open(state.map, state.marker);
        if (refInfoWindow.current) {
          refInfoWindow.current.style.display = 'block';
        }
      }
    },
    [hideTooltip, state.infoWindow, state.map, state.marker]
  );

  const setMapPlaceRef = useRef(setMapPlace);
  setMapPlaceRef.current = setMapPlace;

  useLayoutEffect(() => {
    if (googlePlace && state.map) {
      setMapPlaceRef.current(googlePlace);
      let header: string = googlePlace.name ?? '';
      let info: string = googlePlace.formatted_address ?? '';
      if (!googlePlace.name) {
        const fullName = googlePlace.formatted_address?.split(',') ?? [];
        header = fullName.splice(0, 1)[0] ?? '';
        info = fullName.join(', ') ?? header ?? '';
      }
      setTooltipInfo({
        header,
        info,
      });
    }
  }, [state.map, googlePlace]);

  const reset = () => {
    state.map?.setCenter(defaultCoords);
    state.map?.setZoom(4);
    state.infoWindow?.close();
    state.marker?.setVisible(false);
  };

  return {
    mapRef: ref,
    tooltipRef: refInfoWindow,
    tooltip,
    setMapPlace,
    reset,
    ...state,
  };
};
