/** @jsxImportSource @emotion/react */
import { Dialog, styled } from '@mui/material';

import { shouldNotForwardProps } from '@/utils/shouldNotForwardProps';

const getWidth = (
  size: ModalDialogSizes | undefined,
  width?: number,
  type?: 'max-width'
) => {
  if (width != null) {
    return `${width}px`;
  }

  if (size != null && size !== 'auto') {
    return `${sizesMap[size]}px`;
  } else if (size === 'auto') {
    return type === 'max-width' ? 'initial' : 'auto';
  } else {
    return `${sizesMap.sm}px`;
  }
};
type ModalDialogSizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'auto';
const sizesMap: Record<Exclude<ModalDialogSizes, 'auto'>, number> = {
  xs: 400,
  sm: 480,
  md: 560,
  lg: 640,
  xl: 720,
  xxl: 800,
};

interface DialogAProps {
  size?: ModalDialogSizes;
  fullHeight?: boolean;
  noContentPaddings?: boolean;
  fullFrameMobile?: boolean;
}

export const StyledDialog = styled(
  Dialog,
  shouldNotForwardProps([
    'size',
    'fullHeight',
    'noContentPaddings',
    'fullFrameMobile',
  ])
)<DialogAProps>(
  ({ theme: t, size, fullHeight, fullFrameMobile, noContentPaddings }) => ({
    borderRadius: 0,
    '.MuiPaper-root': {
      borderRadius: 0,
      color: 'inherit',
      width: getWidth(size, undefined),
      margin: 0,
      maxWidth: size === 'xl' || size === 'auto' ? 'initial' : '',
      minHeight: fullHeight ? '90%' : '',
      '&.MuiDialog-paperWidthSm': {
        [t.breakpoints.down('sm')]: {
          width: 'calc(100% - 32px)',
          marginRight: 0,
          marginLeft: 0,
        },
      },
      [t.breakpoints.down('sm')]: {
        minWidth: fullFrameMobile ? '100%' : 'initial !important',
        minHeight: fullFrameMobile ? '100%' : undefined,
      },
      '&.MuiDialog-paperFullWidth': {
        width: '96%',
      },
    },
    '.MuiDialogContent-root': {
      textAlign: size === 'sm' ? 'center' : 'left',
      paddingLeft: noContentPaddings && 0,
      paddingRight: noContentPaddings && 0,
    },
    '.MuiDialogActions-root': {
      justifyContent:
        size === 'md'
          ? 'space-between !important;'
          : size === 'sm'
          ? 'center'
          : 'initial',
    },
  })
);
StyledDialog.defaultProps = {
  keepMounted: false,
  size: 'xs',
  disableScrollLock: true,
};
