import {
  PassportDocument,
  StateCode,
  USABCDocument,
  USADLIDDocument,
  USAGCDocument,
} from './fromSchema';
import {
  AccountCompanyStatus,
  AccountStatus,
  Email,
  MobilePhone,
  Module,
} from '@/api/__generated__/webApi';

export type UUID = string;
export type NPI = number;

export type AccountDocument =
  | PassportDocument
  | USAGCDocument
  | USABCDocument
  | USADLIDDocument;

export type ISODateString = string; //  2022-10-12T09:44:04.816Z or just 2022-10-12

export interface PrimaryTaxonomy {
  code: string;
  description: string;
  group: string;
  license: string;
  stateCode: StateCode;
}

export enum RoleName {
  Owner = 'Owner',
}
export interface Role {
  description?: string;
  editable?: boolean;
  id: UUID;
  name?: string | RoleName.Owner;
}

export interface Permission {
  description?: string;
  id: UUID;
  removable?: boolean;
}
export interface ModuleRoles {
  module: Module;
  roles: Role[];
}
export interface InviteUserData {
  contact: User;
  phone: null | string;
}
export interface User {
  id: UUID;
  firstName?: string;
  lastName?: string;
  phone?: string;
  avatarUrl?: string;
  domainEmail?: string;
  moduleRoles?: ModuleRoles[];
  birthDate?: ISODateString;
  accountStatus?: AccountStatus;
  accountCompanyStatus?: AccountCompanyStatus;
  inviteExpiredTime?: ISODateString;
  phones?: MobilePhone[];
  emails?: Email[];
}

export interface Page<T> {
  hasNext: boolean;
  items: T[];
}

// eslint-disable-next-line
export type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

export interface Address {
  additional?: string;
  city?: string;
  id: UUID;
  main?: string;
  postalCode?: string;
  state: StateCode;
}

export enum AuthCodeType {
  Registration = 'REGISTRATION',
  Activation = 'ACTIVATION',
  RecoveryPhone = 'RECOVERY_PHONE',
  RecoveryEmail = 'RECOVERY_EMAIL',
  TwoFactor = 'TWO_FACTOR',
}

export enum OpenIdType {
  Adfs = 'ADFS',
  Keycloak = 'KEYCLOAK',
}

export interface ADFSOpenIdSettings {
  clientId: UUID;
  clientSecret: string;
  discoveryUrl: string;
  type: OpenIdType.Adfs;
}
export interface KeycloakOpenIdSettings {
  clientId: UUID;
  clientSecret: string;
  discoveryUrl: string;
  type: OpenIdType.Keycloak;
}
export type OpenIdSettings = (ADFSOpenIdSettings | KeycloakOpenIdSettings) & {
  enable: boolean;
  authorized?: boolean;
};
export type TimestampMs = number; // unix timestamp like but in milliseconds
export type CompanyUUID = UUID;
export type ModuleUUID = UUID;
export type RoleUUID = UUID;
export type RecaptchaToken = string; // long string received from Google recaptcha token
export interface CompanyPermissions {
  companyId: UUID;
  permissions: CompanyPermissionName[];
  permissionsDigest: string;
}

export enum CompanyPermissionName {
  ManualCreateUser = 'manual.create.user',
  CompanyEditDescription = 'company.edit.description',
  CompanyEditName = 'company.edit.name',
  CompanyAssignRole = 'company.assign.role',
  CompanyRevokeRole = 'company.revoke.role',
  CompanyViewAllUsers = 'company.view.all.users',
  CompanyViewAllRoles = 'company.view.all.roles',
  CompanyInviteUser = 'company.invite.user',
  CompanyRemoveUsers = 'company.remove.users',
  CompanyManageAddresses = 'company.manage.addresses',
  CompanyManageInsuranceCarrier = 'company.manage.insurance_carrier',
  CompanyConnectModule = 'company.connect.module',
  CompanyEditLogo = 'company.edit.logo',
  ModuleCreateCustomRole = 'module.create.custom.role',
  ModuleEditCustomRole = 'module.edit.custom.role',
  ModuleRenameCustomRole = 'module.rename.custom.role',
  EditDomainEmailCompanyUser = 'edit.domainemail.company.user',
  ExternalAuthenticationConfigure = 'external.authentication.configure',
}
export enum PhoneType {
  MOBILE_PHONE = 'MOBILE_PHONE',
  WORK_PHONE = 'WORK_PHONE',
  HOME_PHONE = 'HOME_PHONE',
}
interface CommonPhone {
  id: string;
  phone: string;
}
export interface MobilePhone_ extends CommonPhone {
  type: PhoneType.MOBILE_PHONE;
  allowResultsDelivery: boolean | null;
  main: boolean | null;
  ownershipConfirmed: boolean | null;
}
export interface HomePhone extends CommonPhone {
  type: PhoneType.HOME_PHONE;
}
export interface WorkPhone extends CommonPhone {
  type: PhoneType.WORK_PHONE;
}
export type Phone = MobilePhone_ | HomePhone | WorkPhone;

export interface ResendTime {
  /**
   * @desc
   * Time in the future, in ms
   */
  resendTime: TimestampMs;
}

export interface AccountSearchResponse {
  accountCompanyStatus: AccountCompanyStatus;
  accountStatus: AccountStatus;
  avatarUrl?: string;
  emails: Email[];
  phones: MobilePhone[];
  firstName: string;
  lastName: string;
  inviteExpiredTime: ISODateString;
  npi: number;
  id: UUID;
}

export type Nullable<T> = { [K in keyof T]: T[K] | null };
