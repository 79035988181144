import { AccountTOTP } from '@/api/__generated__/webApi';
import {
  createNormalizedState,
  deleteByIdFromNormalizedState,
  NormalizedState,
  updateNormalizedState,
} from '@/store/common/normalized';
import { UUID } from '@/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createQr, deleteQr, getQrs, updateQr } from '@/store/profile-qr/api';
import { ValidationErrorType } from '@/types/ValidationError';
import { globalStateResetAction } from '@/store/common/actions';

type QrHandlingSteps = 'create-new' | 'edit';
interface ProfileQrState {
  qrs: NormalizedState<AccountTOTP>;
  selectedQrId: null | undefined | UUID;
  step: QrHandlingSteps | null;
  validationErrors: Record<string, ValidationErrorType>;
}
const initialState: ProfileQrState = {
  qrs: createNormalizedState([]),
  selectedQrId: null,
  step: null,
  validationErrors: {},
};

export const profileQrSlice = createSlice({
  name: 'qrState',
  initialState,
  reducers: {
    createNew(state) {
      state.step = 'create-new';
    },
    resetErrors(state) {
      state.validationErrors = {};
    },
    resetStep(state) {
      state.step = null;
    },
    reset() {
      return initialState;
    },
    editQr(state, { payload }: PayloadAction<{ id: UUID }>) {
      state.step = 'edit';
      state.selectedQrId = payload.id;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getQrs.fulfilled, (state, { payload }) => {
      updateNormalizedState(state.qrs, payload);
    });

    builder.addCase(createQr.fulfilled, (state, { payload }) => {
      updateNormalizedState(state.qrs, [payload], 0);
    });
    builder.addCase(createQr.rejected, (state, { payload: e }) => {
      if (e) state.validationErrors = e;
    });

    builder.addCase(updateQr.fulfilled, (state, { meta }) => {
      const item = state.selectedQrId
        ? state.qrs.byID[state.selectedQrId]
        : null;
      if (item) item.description = meta.arg.description;
    });
    builder.addCase(updateQr.rejected, (state, { payload: e }) => {
      if (e) state.validationErrors = e;
    });
    builder.addCase(deleteQr.fulfilled, (state, { meta }) => {
      if (state.selectedQrId)
        deleteByIdFromNormalizedState(state.qrs, [state.selectedQrId]);
    });
    builder.addCase(globalStateResetAction, () => {
      return initialState;
    });
  },
});

export const qrActions = profileQrSlice.actions;
