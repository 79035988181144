import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TKeys } from '@/i18n/useTranslate';
import { globalStateResetAction } from '@/store/common/actions';

export interface QueryData<T = any> {
  fetching?: boolean;
  success?: boolean;
  responseData?: T;
  validationErrors?: {
    fieldErrors?: Record<string, TKeys<'common'>>;
    error?: TKeys<'common'>;
    count?: number;
  };
}
export interface QueryState<T = any> {
  requests: Record<string, QueryData<T>>;
  tags: Record<
    string,
    | QueryPayloadActions['query']['payload']
    | QueryPayloadActions['mutationQuery']['payload']
  >;
}
const initialState: QueryState = {
  requests: {},
  tags: {},
};
const slice = createSlice({
  name: 'queryState',
  initialState,
  reducers: {
    // not for direct usage
    _query(state, action: QueryPayloadActions['query']) {
      const path = action.payload.path;
      if (!state.requests[path]) {
        state.requests[path] = {};
      }
      state.requests[path].fetching = true;
      if (action.payload.tag) {
        state.tags[action.payload.tag] = action.payload;
      }
    },
    _mutationQuery(state, action: QueryPayloadActions['mutationQuery']) {
      if (action.payload.replace?.in) {
        const { in: tag, replacer } = action.payload.replace;
        const tagPayload = state.tags[tag];
        const replacePath = tagPayload.path;
        if (!state.requests[replacePath]) {
          state.requests[replacePath] = {};
        }
        const requestInfo = state.requests[replacePath];
        requestInfo.responseData =
          replacer?.(requestInfo.responseData, action.payload.requestData) ??
          action.payload.requestData;
      }
      if (action.payload.tag) {
        state.tags[action.payload.tag] = action.payload;
      }
    },
    _setProgress(state, action: QueryPayloadActions['setProgress']) {
      state.requests[action.payload.path] = {
        ...state.requests[action.payload.path],
        fetching: action.payload.fetching,
        success: action.payload.success,
      };
    },
    _clearData(state, action: QueryPayloadActions['clearData']) {
      state.requests[action.payload.path] = {};
    },
    _querySuccess(state, action: QueryPayloadActions['querySuccess']) {
      state.requests[action.payload.path] = {
        ...state.requests[action.payload.path],
        responseData: action.payload.data,
        validationErrors: undefined,
      };
    },
    _setValidationErrors(
      state,
      action: QueryPayloadActions['validationErrors']
    ) {
      if (!state.requests[action.payload.path]) {
        state.requests[action.payload.path] = {};
      }
      const validationErrorsCount =
        state.requests[action.payload.path].validationErrors?.count;
      state.requests[action.payload.path].validationErrors = {
        count: (validationErrorsCount ?? 0) + 1,
        fieldErrors: action.payload.errors.fieldErrors?.reduce<any>(
          (acc, err) => {
            acc[err.field] = err.error;
            return acc;
          },
          {}
        ),
        error: action.payload.errors.error as TKeys,
      };
    },
    _setBaseError(state, action) {},
  },
  extraReducers(builder) {
    builder.addCase(globalStateResetAction, () => {
      return initialState;
    });
  },
});
export const {
  _query,
  _querySuccess,
  _mutationQuery,
  _setProgress,
  _setValidationErrors,
  _setBaseError,
  _clearData,
} = slice.actions;

type Keys = keyof typeof slice.actions;
export type ExtendUnionString<T extends string, U extends string> = `${T}/${U}`;
export type ActionNames = ExtendUnionString<typeof slice.name, Keys>;
export type Tag = string;
export interface QueryPayloadActions {
  query: PayloadAction<{ path: string; method?: 'GET'; tag?: Tag }>;
  mutationQuery: PayloadAction<{
    path: string;
    method: 'POST' | 'DELETE' | 'PUT' | 'PATCH';
    invalidateTags?: Tag[];
    tag?: Tag;
    requestData?: any;
    replace?: { in: Tag; replacer?: (oldData: any, newData: any) => any };
  }>;
  querySuccess: PayloadAction<{ path: string; data: any }>;
  validationErrors: PayloadAction<{
    path: string;
    errors: {
      error?: string;
      fieldErrors?: Array<{ field: string; error: string }>;
    };
  }>;
  setProgress: PayloadAction<{
    path: string;
    fetching: boolean;
    success?: boolean;
  }>;
  clearData: PayloadAction<{ path: string }>;
}

export const QueryActionNames: Record<keyof QueryPayloadActions, ActionNames> =
  {
    query: 'queryState/_query',
    querySuccess: 'queryState/_querySuccess',
    mutationQuery: 'queryState/_mutationQuery',
    validationErrors: 'queryState/_setValidationErrors',
    setProgress: 'queryState/_setProgress',
    clearData: 'queryState/_clearData',
  } as const;

export const queryReducer = slice.reducer;
export const queryStateName = slice.name;
