import { styled } from '@mui/material';
import { Button, ButtonProps } from '@/components/Button';
import React, { FC } from 'react';

export const PillsContainer = styled('nav')<{ fullWidth?: boolean }>(
  ({ theme: t, fullWidth }) => ({
    width: fullWidth ? '100%' : '',
    display: 'flex',
    button: {
      flex: '1 1 auto',
      minWidth: 105,
      padding: '0 10px',
      borderWidth: 2,
      borderColor: t.palette.secondary.main,
      '&:hover, &:active': {
        borderWidth: 2,
      },
    },
  })
);

PillsContainer.defaultProps = {
  fullWidth: true,
};
const Pbt: FC<ButtonProps & { selected?: boolean }> = ({
  selected,
  ...restProps
}) => {
  return (
    <Button {...restProps} variant={selected ? 'contained' : 'outlined'} />
  );
};
export const PillButton = styled(Pbt)<{ selected?: boolean }>({
  minWidth: '148px!important',
});
PillButton.defaultProps = {
  fullWidth: true,
  color: 'secondary',
};
