/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { Tooltip } from 'ui-kit';
import { css as _, tooltipClasses, TooltipProps } from '@mui/material';
import { ReactComponent as IconSVGWarning } from '@/images/icons/icon_round_warning.svg';
import { IconContainer } from '@/components/IconContainer';
export const AlertSign: FC<{
  title: string;
  className?: string;
  placement?: TooltipProps['placement'];
}> = ({ title, className, placement }) => {
  return (
    <Tooltip
      placement={placement ?? 'bottom'}
      title={title ?? ''}
      css={_`.${tooltipClasses.tooltip} {
      max-width: 200px
    }`}
    >
      <IconContainer css={_`cursor: pointer`} className={className}>
        <IconSVGWarning />
      </IconContainer>
    </Tooltip>
  );
};
